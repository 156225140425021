import React, { useState, useEffect } from "react";
import { Button, Checkbox, Typography } from "@mui/material";
import { connect } from "react-redux";
import { fetchUserCollections } from "../../store/collections";
import { useNavigate } from "react-router-dom";
import ItemCard from "../../components/Collections/ItemCard";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const NUM_TO_FETCH = 5;

function CollectionHome(props) {
	const [showInactiveCollections, setShowInactiveCollections] = useState(true);

	useEffect(() => {
		props.fetchUserCollections(0, NUM_TO_FETCH);
	}, []);

	const navigate = useNavigate();

	return (
		<div>
			<Grid
				container
				direction="row"
				alignItems="center"
				justifyContent="space-between" style={{ paddingRight: '2rem', paddingLeft: '2rem' }}
			>
				<Grid item>
					<div style={{ display: "flex" }}>
						<Checkbox
							checked={showInactiveCollections}
							onChange={(e) => {
								setShowInactiveCollections(e.target.checked);
							}}
						/>
						<Typography style={{ marginTop: "8px" }}>Show inactive collections</Typography>
					</div>
				</Grid>
				<Grid item>
					<Button
						variant="contained"
						onClick={() => {
							navigate('/collection/create-collection')
						}}>
						Create Collection
					</Button>
				</Grid>
			</Grid>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ overflow: 'auto', padding: '2rem', paddingTop: '30px', flexGrow: 1, height: "70vh" }}>
					{props.collections_list.length !== 0 &&
						props.collections_list.map((item, index) => {
							if (!showInactiveCollections && item.status === "INACTIVE")
								return <div key={index}></div>;
							return (
								<div
									key={index}
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "20px",
									}}>
									<ItemCard props={{ item: props.collections_list[index], showEditOptions: true }} />
								</div>
							);
						})}
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100vw',
					padding: "10px"
				}}
			>
				<Button
					variant="outlined"
					onClick={() => {
						props.fetchUserCollections(props.collections_list.length, NUM_TO_FETCH);
					}}>
					Fetch More
				</Button>
			</Box>
		</div>
	);
}

const mapStateToProps = (state, ownProps) => ({
	...ownProps,
	collections_list: state.collections.collections_list,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserCollections: (start_from, num_to_fetch) => dispatch(fetchUserCollections(start_from, num_to_fetch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CollectionHome);
