import React from 'react';
import { download_images } from '../../store/middleware/api';
import { Button } from '@mui/material';

const DownloadButton = ({ collection_id }) => {
    const buttonStyle = {
        marginRight: '8px',
        backgroundColor: '#FFC3B7', // Pastel red color
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
        transition: 'background-color 0.3s',
    };

    const handleButtonClick = (collection_id) => {
        console.log("Sending data:", collection_id);
        download_images(collection_id);
    };
    return (
        <Button style={buttonStyle} onClick={() => handleButtonClick(collection_id)}>
            Download Images
        </Button>
    );
};
export default DownloadButton;
