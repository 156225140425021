export const API_HOST = "https://api.zive.club"

export const AuthUrls = {
    LOGIN: `/auth/login/`,
    REGISTER: `/auth/register/`,
    REQUEST_OTP: `/auth/request_login_otp/`,
    REQUEST_REGISTER_OTP: `/auth/request_register_otp/`,
    OTP_LOGIN: `/auth/otp_login/`,
    VERIFY_OTP: `/auth/verify_otp/`,
    CHANGE_PASSWORD: `/auth/change_password/`,
    RESET_PASSWORD: `/auth/reset_password/`,
    UPDATE_NAME: `/auth/update_user_name/`,
};