import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage, WarningMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "affiliate",
    initialState: {
        collection: {},
        id_wishlink_map: {},
        submitting_urls: false,
        loading_urls: false,
    },
    reducers: {
        fetchCollectionAffiliateLinksMappingStarted: (state, action) => { },
        fetchCollectionAffiliateLinksMappingSuccess: (state, action) => {
            if (action.payload.data.id && action.payload.server_response.wishlink_mapping) {
                state.id_wishlink_map = {
                    ...state.id_wishlink_map,
                    [action.payload.data.id]: action.payload.server_response.wishlink_mapping
                }
            }
        },
        fetchCollectionAffiliateLinksMappingFailed: (state, action) => {
            ErrorMessage("Error unpublishing collections");
        },
        submitUrlsToWishlinkStarted: (state, action) => {
            state.submitting_urls = true;
        },
        submitUrlsToWishlinkSuccess: (state, action) => {
            state.submitting_urls = false;
            SuccessMessage(action.payload.server_response.status)
        },
        submitUrlsToWishlinkFailed: (state, action) => {
            state.submitting_urls = false;
            console.log(action.payload);
            ErrorMessage("Error submitting urls to wishlink");
        },
        loadUrlsFromWishlinkStarted: (state, action) => {
            state.loading_urls = true;
        },
        loadUrlsFromWishlinkSuccess: (state, action) => {
            SuccessMessage(action.payload.server_response.status);
            state.loading_urls = false;
        },
        loadUrlsFromWishlinkFailed: (state, action) => {
            state.loading_urls = false;
            ErrorMessage("Error submitting urls to wishlink");
        }
    },
});


export const {
    fetchCollectionAffiliateLinksMappingStarted,
    fetchCollectionAffiliateLinksMappingSuccess,
    fetchCollectionAffiliateLinksMappingFailed,
    submitUrlsToWishlinkStarted,
    submitUrlsToWishlinkSuccess,
    submitUrlsToWishlinkFailed,
    loadUrlsFromWishlinkStarted,
    loadUrlsFromWishlinkSuccess,
    loadUrlsFromWishlinkFailed

} = slice.actions;

export default slice.reducer;

export const fetchCollectionAffiliateLinksMapping = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/fetch_collection_affiliate_links_with_id/",
            method: "post",
            data_to_server: { id: id },
            data: { id: id },
            onStart: fetchCollectionAffiliateLinksMappingStarted.type,
            onSuccess: fetchCollectionAffiliateLinksMappingSuccess.type,
            onError: fetchCollectionAffiliateLinksMappingFailed.type,
        })
    );
};


export const submitUrlsToWishlink = (urls, auth_token) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/add_wishlink_urls_to_queue/",
            method: "post",
            data_to_server: { urls: urls, auth_token: auth_token },
            data: {},
            onStart: submitUrlsToWishlinkStarted.type,
            onSuccess: submitUrlsToWishlinkSuccess.type,
            onError: submitUrlsToWishlinkFailed.type,
        })
    );
};

export const loadUrlsFromWishlink = (urls, auth_token) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/lookup_recently_added_urls_to_queue/",
            method: "post",
            data_to_server: { urls: urls, auth_token: auth_token },
            data: {},
            onStart: loadUrlsFromWishlinkStarted.type,
            onSuccess: loadUrlsFromWishlinkSuccess.type,
            onError: loadUrlsFromWishlinkFailed.type,
        })
    );
};
