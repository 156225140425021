import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


export function GetDiscountedPriceFromFeedResponse(product) {
    if (product == undefined) {
        return -1;
    }

    let discounted_price = product.price.discounted_price;
    if (product.product_shop != undefined && product.product_shop.discount_price != undefined) {
        discounted_price = product.product_shop.discount_price;
    }
    return discounted_price;
}


function ToggleCategory({ source, handleSourceChange }) {
    return (
        <ToggleButtonGroup
            value={source}
            exclusive
            onChange={handleSourceChange}
            aria-label="source"
        >
            <ToggleButton value="google" aria-label="google">
                <p>Google</p>
            </ToggleButton>
            <ToggleButton value="myntra" aria-label="myntra">
                <p>Myntra</p>
            </ToggleButton>
            <ToggleButton value="nykaa" aria-label="nykaa">
                <p>Nykaa</p>
            </ToggleButton>
            <ToggleButton value="ajio" aria-label="ajio">
                <p>Ajio</p>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}


function ProductImageList({ category, results, retrieval_query, updateSelectedProductForCategory, selectedUrl }) {
    const [imageList, setImageList] = useState([]);
    const [source, setSource] = React.useState('google');
    const handleSourceChange = (
        event,
        newSource,
    ) => {
        setSource(newSource);
    };

    useEffect(() => {
        var images_list = [];
        if (results.length > 0 && retrieval_query.query.broad_category != category) {
            return;
        }
        for (var index = 0; index < results.length; index++) {
            var image = "https://images.unsplash.com/photo-1584824486509-112e4181ff6b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80";
            if (results[index].features) {
                image = results[index].features.product.media.base_image !== "" ? results[index].features.product.media.base_image : results[index].features.product.media.additional_images[0];
            }
            if (!image) {
                image = "https://images.unsplash.com/photo-1584824486509-112e4181ff6b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
            }
            // { value: results[index].features.product.brand, title: "brand" },
            // { value: index, title: index },
            if (source == results[index].source[0]) {
                images_list.push(
                    {
                        src: image,
                        width: 400,
                        height: 400,
                        tags: [
                            { value: "Rs." + GetDiscountedPriceFromFeedResponse(results[index].features.product), title: "price" },
                            { value: results[index].features.product.brand, title: "brand" },
                        ],
                        isSelected: results[index].features.product.url == selectedUrl
                    },
                );
            }
        }
        setImageList(images_list);
    }, [category, results, source]);

    const handleSelect = (index, item, event) => {
        // const nextImages = imageList.map((image, i) =>
        //     i === index ? { ...image, isSelected: !image.isSelected } : image
        // );
        var nextImages = [];
        if (!item.isSelected) {
            nextImages = imageList.map((image, i) =>
                i === index ? { ...image, isSelected: true } : { ...image, isSelected: false }
            );
            var effective_index = -1;
            for (var i = 0; i < results.length; i++) {
                if (source == results[i].source[0]) {
                    effective_index += 1;
                }
                if (effective_index == index) {
                    break
                }
            }
            // Get the corresponding mapped index in larger list, corresponding to source specific index.
            updateSelectedProductForCategory(results[i].features.product, category);
        }
        else {
            nextImages = imageList.map((image, i) =>
                i === index ? { ...image, isSelected: false } : { ...image, isSelected: false }
            );
            updateSelectedProductForCategory(null, category);
        }
        setImageList(nextImages);
    };

    const tagStyle = { background: "black", opacity: 0.7, color: "white", padding: "1px" };
    return (
        <>
            <ToggleCategory
                source={source}
                handleSourceChange={handleSourceChange}
            ></ToggleCategory>
            <Gallery images={imageList} onSelect={handleSelect} tagStyle={tagStyle} />

        </>

    )
}

// function ProductImageList({ category, results }) {
//     const [selectedProductUrl, setSelectedProductUrl] = useState("");


//     const handleImageSelect = (result) => {
//         setSelectedProductUrl(result.url);
//         console.log("Seclted image url", result, selectedProductUrl);
//     };


//     useEffect(() => {
//         console.log("useEffect: ", category, results);
//     }, [category, results]);

//     // const imageListItemStyle = {
//     //   transition: "box-shadow 0.3s",
//     // };


//     // const imageListItemHoverStyle = {
//     //   boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.2)",
//     // };


//     return results && results.length > 0 ? (
//         <ImageList
//             sx={{
//                 display: "flex",
//                 justifyContent: "center",
//                 overflow: "scroll",
//                 transition: "opacity 0.3s",
//                 width: 1000,
//                 height: 500,
//             }}
//             cols={3}
//         >
//             {results.map((result, index) => (
//                 <ImageListItem
//                     key={result.url}
//                     onClick={() => {
//                         handleImageSelect(result);
//                         console.log(result);
//                     }}
//                     sx={{
//                         "&:hover": {
//                             opacity: "0.6",
//                         },
//                         "&.selected": {
//                             border: "3px solid blue",
//                         },
//                     }}
//                     className={result.url === selectedProductUrl ? "selected" : ""}
//                 >
//                     <div>{index}</div>
//                     <img
//                         src={result.features.product.media.base_image !== "" ? result.features.product.media.base_image : result.features.product.media.additional_images[0]}
//                         alt={result.url}
//                         loading="lazy"
//                         style={{ minWidth: 400, minHeight: 400 }}
//                     />
//                 </ImageListItem>
//             ))}
//         </ImageList>
//     ) : (
//         ""
//     );
// }


export default ProductImageList;