import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhotoGallery from "./OutfitBoards/PhotoGallery";
import { NavBarData } from "./NavBarData";
import UploadPhotos from "./Common/UploadPhotos";

function NavBar() {
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);

  const baseStyle = {
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    color: hovered ? '#0077cc' : 'inherit',
    textDecoration: hovered ? 'underline' : 'none'
  };

  return (
    <div className="flex w-screen  p-4 items-center">
      <div
        className="flex-1 text-2xl font-bold"
        style={baseStyle}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => { navigate('/') }}
      >
        Creator Platform
      </div>
      {NavBarData.map((item, index) => {
        return (
          <div
            className={
              window.location.pathname == item.link
                ? "p-4   rounded-xl  text-blue-500 font-semibold text-xl cursor-pointer flex justify-center"
                : "p-4   rounded-xl  text-black font-semibold text-xl cursor-pointer flex justify-center"
            }
            onClick={() => navigate(`${item.link}`)}
            key={index}
          >
            {item.title}
          </div>
        );
      })}
    </div>
  );
}

export default NavBar;
