import React, { useState, useEffect } from "react";
import { Button, Checkbox, Typography, Box, Backdrop, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import { fetchCollectionAffiliateLinksMapping, submitUrlsToWishlink, loadUrlsFromWishlink } from "../../store/affiliateCollectionUpdate";
import { useParams, useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails, Divider, Link, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ErrorMessage } from "../../Controllers/Notifications";

function validateToken(token) {
    // Check if token starts with "Token "
    if (!token.startsWith("Token ")) {
        return false;
    }

    // Extract the JWT part
    const jwt = token.split("Token ")[1];

    // Ensure the JWT has three parts separated by dots
    const parts = jwt.split(".");
    if (parts.length !== 3) {
        return false;
    }

    // Optionally: Ensure each part is not empty
    for (let part of parts) {
        if (!part.trim()) {
            return false;
        }
    }

    return true;
}


const DisplayItems = ({ items, submitUrlsToWishlink, loadUrlsFromWishlink }) => {
    const emptyAffiliateLinks = items.filter(item => !item.affiliate_link);
    const filledAffiliateLinks = items.filter(item => item.affiliate_link);
    const [authToken, setAuthToken] = useState(''); // State to track the value of the text field

    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60); // for the 1-minute countdown

    useEffect(() => {
        let timer;
        if (buttonsDisabled) {
            timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
        }

        if (countdown === 0) {
            clearInterval(timer);
            setButtonsDisabled(false);
            setCountdown(60); // reset the countdown
        }

        return () => clearInterval(timer); // clean up on unmount
    }, [buttonsDisabled, countdown]);

    const handleSubmitUrls = () => {
        const uniqueUrls = [...new Set(emptyAffiliateLinks.map(item => item.url))];
        if (authToken == '') {
            ErrorMessage("Auth token is empty")
        }
        else if (!validateToken(authToken)) {
            ErrorMessage("Incorrect authToken format");
        } else {
            if (uniqueUrls.length > 0) {
                submitUrlsToWishlink(uniqueUrls, authToken);
                setButtonsDisabled(true);
            }
        }
    };

    const handleLoadUrls = () => {
        const uniqueUrls = [...new Set(emptyAffiliateLinks.map(item => item.url))];
        if (authToken == '') {
            ErrorMessage("Auth token is empty")
        }
        else if (!validateToken(authToken)) {
            ErrorMessage("Incorrect authToken format");
        } else {
            if (uniqueUrls.length > 0) {
                loadUrlsFromWishlink(uniqueUrls, authToken);
            }
        }
    };

    return (
        <div style={{ marginTop: "50px" }}>
            <TextField
                fullWidth
                label="Auth Token"
                variant="outlined"
                value={authToken}
                onChange={(e) => setAuthToken(e.target.value)}
                style={{ marginBottom: '20px' }}
            />
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Items with Empty Affiliate Links</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Button
                        variant="contained"
                        onClick={handleSubmitUrls}
                        disabled={buttonsDisabled}
                        style={{ marginRight: '10px', backgroundColor: 'lightblue' }}
                    >
                        Submit Urls to Wishlink
                    </Button>
                    <Button
                        variant="contained"
                        style={{ backgroundColor: 'lightblue' }}
                        disabled={buttonsDisabled}
                        onClick={handleLoadUrls}
                    >
                        Load Urls From Wishlink
                    </Button>
                    <Typography style={{ marginLeft: '10px' }}>
                        {buttonsDisabled ? `Re-enable in: ${countdown}s` : ''}
                    </Typography>
                    <List>
                        {emptyAffiliateLinks.map((item, idx) => (
                            <ListItem key={idx}>
                                <ListItemText>
                                    <Typography variant="body1" display="inline">Board:{item.index + 1} / {item.category_key}:  </Typography>
                                    <Link href={item.url} target="_blank" rel="noopener noreferrer" display="inline">
                                        {item.url.length > 100 ? `${item.url.substring(0, 100)}...` : item.url}
                                    </Link>
                                    <TextField
                                        label="Affiliate Link"
                                        value={item.affiliate_link || ''}
                                        disabled={true}
                                    />
                                    <Button
                                        size="small"
                                        onClick={() => window.open(item.affiliate_link, '_blank')}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <OpenInNewIcon />
                                    </Button>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Divider variant="middle" style={{ margin: '20px 0' }} />

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Items with Affiliate Links</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {filledAffiliateLinks.map((item, idx) => (
                            <ListItem key={idx}>
                                <ListItemText>
                                    <Typography variant="body1" display="inline">Board:{item.index + 1} / {item.category_key}:  </Typography>
                                    <Link href={item.url} target="_blank" rel="noopener noreferrer" display="inline">
                                        {item.url.length > 100 ? `${item.url.substring(0, 100)}...` : item.url}
                                    </Link>
                                    <TextField
                                        label="Affiliate Link"
                                        value={item.affiliate_link || ''}
                                        disabled={true}
                                    />
                                    <Button
                                        size="small"
                                        onClick={() => window.open(item.affiliate_link, '_blank')}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        <OpenInNewIcon />
                                    </Button>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

function CreateBulkAffiliate({ id_wishlink_map, submitting_urls, loading_urls, fetchCollectionAffiliateLinksMapping, submitUrlsToWishlink, loadUrlsFromWishlink }) {
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchCollectionAffiliateLinksMapping(id);
        }
    }, [id]);

    return (
        <div style={{ position: 'relative' }}> {/* Container div to position the Backdrop */}
            <Box style={{
                maxHeight: '80vh',
                overflow: 'auto'
            }}>
                {id &&
                    <Button
                        size="small"
                        onClick={() => navigate('/collection/edit/' + id)}
                        style={{ marginLeft: '10px' }}
                    >
                        Go to collection
                    </Button>
                }
                <DisplayItems
                    items={id_wishlink_map && id_wishlink_map[id] ? id_wishlink_map[id] : []}
                    submitUrlsToWishlink={submitUrlsToWishlink}
                    loadUrlsFromWishlink={loadUrlsFromWishlink}
                ></DisplayItems>
            </Box>

            {/* Loading Overlay */}
            <Backdrop open={submitting_urls || loading_urls} style={{ zIndex: 1, color: '#fff' }}>
                <CircularProgress color="inherit" />
                <br />
                {submitting_urls && <div style={{ marginTop: '20px' }}>Submitting URLs...</div>}
                {loading_urls && <div style={{ marginTop: '20px' }}>Loading URLs...</div>}
            </Backdrop>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    id_wishlink_map: state.affiliate.id_wishlink_map,
    submitting_urls: state.affiliate.submitting_urls,
    loading_urls: state.affiliate.loading_urls
});

const mapDispatchToProps = (dispatch) => ({
    fetchCollectionAffiliateLinksMapping: (id) => dispatch(fetchCollectionAffiliateLinksMapping(id)),
    submitUrlsToWishlink: (urls, authToken) => dispatch(submitUrlsToWishlink(urls, authToken)),
    loadUrlsFromWishlink: (urls, authToken) => dispatch(loadUrlsFromWishlink(urls, authToken)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateBulkAffiliate);