import React from "react";
import { Card, Box, Button, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DEFAULT_IMAGE = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJdoo2ir60MPwYQnzx9uHNgqNCam0B4-58dnnqsOLeNw&s"
function MediaCollectionCard(props) {
	const { item, showEditOptions } = props.props;
	const navigate = useNavigate();

	return (
		<div>
			<Card
				sx={{ display: "flex" }}
				style={{ width: "600px", marginLeft: "10px" }}
				variant="outlined">
				<img
					src={item.media.representative_image ? item.media.representative_image : DEFAULT_IMAGE}
					alt="Collection Thumbnail"
					style={{ width: "150px", height: "auto" }}
				/>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<CardContent sx={{ display: "flex", flexDirection: "column" }}>
						<Typography component="span" variant="h5">
							{item.visible_name}
						</Typography>

						<Typography component="span" variant="h6">
							{item.subtitle}
						</Typography>
					</CardContent>
					{showEditOptions && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "10px",
							}}>
							<div style={{ marginLeft: "10px" }}>
								<Button
									variant="outlined"
									onClick={(e) => {
										e.preventDefault();
										navigate(`/collection/edit/${item.id}`);
										// console.log("Redirect to", `/collection/edit/${item.id}`)
									}}>
									Edit
								</Button>
							</div>
							{item.creation_status &&
								<Typography component="span" variant="body">
									{item.creation_status}
								</Typography>
							}
						</div>
					)}
				</Box>
			</Card>
		</div>
	);
}

export default MediaCollectionCard;
