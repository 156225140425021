import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import HomePage from "./Pages/HomePage";
import OutfitBoard from "./Pages/OutfitBoards/OutfitBoard";
import PhotoPage from "./Pages/OutfitBoards/PhotoPage";
import RecreateLook from "./Pages/OutfitBoards/RecreateLook";
import SearchPage from "./Pages/Search/SearchPage";
import CreateOutfitBoard from "./Pages/OutfitBoards/CreateOutfitBoard";
import UploadOutfitBoardPage from "./Pages/OutfitBoards/uploadBoard";
import CollectionHome from "./Pages/Collections/Home";
import CollectionInstaHome from "./Pages/InstagramUpdate/Home";
import CreateBulkAffiliate from './Pages/CreateBulkAffiliate/Home'

import CreateOrEditCollections from "./Pages/Collections/CreateOrEditCollections";
import Logout from './Pages/Logout';
import { connect } from "react-redux";

import Signin from "./Pages/Auth/Signin";
// Fixed imports
import FixedNotification from './Controllers/Notifications';

function RequireAuth({ access_token, children }) {
  let location = useLocation();

  if (access_token == undefined) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}


function App(props) {
  return (
    <div className="bg-white overflow-hidden  h-screen w-screen">
      <FixedNotification />
      <div className="flex justify-center  sticky  flex">
        <NavBar />
      </div>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/collection" element={
          <RequireAuth access_token={props.access_token}>
            <CollectionHome />
          </RequireAuth>
        } />
        <Route exact path="/collection_insta" element={
          <RequireAuth access_token={props.access_token}>
            <CollectionInstaHome />
          </RequireAuth>
        } />
        <Route
          exact
          path="/collection/create-collection"
          element={
            <RequireAuth access_token={props.access_token}>
              <CreateOrEditCollections />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/collection/edit/:id"
          element={
            <RequireAuth access_token={props.access_token}>
              <CreateOrEditCollections />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/collection/bulk_affiliate/:id"
          element={
            <RequireAuth access_token={props.access_token}>
              <CreateBulkAffiliate />
            </RequireAuth>
          }
        />
        <Route path="/outfits/finalPage" element={<PhotoPage />} />
        <Route path="/outfits/recreate/:board_id" element={<RecreateLook />} />
        <Route path="/outfits/recreate/" element={<RecreateLook />} />
        <Route path="/outfits/outfit" element={<OutfitBoard />} />
        <Route path="/outfits/createOutfitBoard" element={<CreateOutfitBoard />} />
        <Route path="/outfits/uploadOutfitBoard" element={<UploadOutfitBoardPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/signin" element={<Signin />} />
        < Route path="/logout" element={< Logout />} />
      </Routes>
    </div>
  );
}

const mapStateToProps = (state) => ({
  access_token: state.auth.access_token,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
