import React, { useState } from "react";
import { connect } from "react-redux";
import SchematicForm from "../../components/SchematicForms";
import { useCustomForm } from '../../components/SchematicForms/CustomForm';
import { GetDefaultValuesFromSchema } from '../../components/SchematicForms';

const imageCdnFormFieldsSchema = [
    {
        "key": "image_cdn",
        "type": "MULTISTRING",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    }
];


const videoCdnFormFieldsSchema = [
    {
        "key": "video_cdn",
        "type": "MULTISTRING",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    }
];

const productUrlsFormFieldsSchema = [
    {
        "key": "product_url",
        "type": "MULTISTRING",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    }
];

const imageUploadFormFieldsSchema = [
    {
        "key": "image_cdn",
        "type": "IMAGE_UPLOADER",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    }
];

export function AddImageCDNPage({ addImagesToList }) {
    const [initialValues, setInitialValues] = useState(GetDefaultValuesFromSchema(imageCdnFormFieldsSchema));

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            <SchematicForm
                formFieldsSchema={imageCdnFormFieldsSchema}
                values={values}
                handleChange={handleChange}
                addRepeatedFieldValue={addRepeatedFieldValue}
                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                is_image_uploading={null}
                uploaded_images={null}
                uploadBase64ImagesFn={() => { }}
                clearUploadedImagesFn={() => { }}
                stepToLabel={{}}
                onSubmit={(formValues) => { addImagesToList(formValues.image_cdn); }}
            />
        </div>
    );
}

export function AddVideoCDNPage({ addVideosToList }) {
    const [initialValues, setInitialValues] = useState(GetDefaultValuesFromSchema(videoCdnFormFieldsSchema));

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            <SchematicForm
                formFieldsSchema={videoCdnFormFieldsSchema}
                values={values}
                handleChange={handleChange}
                addRepeatedFieldValue={addRepeatedFieldValue}
                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                is_image_uploading={null}
                uploaded_images={null}
                uploadBase64ImagesFn={() => { }}
                clearUploadedImagesFn={() => { }}
                stepToLabel={{}}
                onSubmit={(formValues) => { addVideosToList(formValues.video_cdn) }}
            />
        </div>
    );
}

export function AddProductUrlPage({ addProductUrlsToList }) {
    const [initialValues, setInitialValues] = useState(GetDefaultValuesFromSchema(productUrlsFormFieldsSchema));

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            <SchematicForm
                formFieldsSchema={productUrlsFormFieldsSchema}
                values={values}
                handleChange={handleChange}
                addRepeatedFieldValue={addRepeatedFieldValue}
                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                is_image_uploading={null}
                uploaded_images={null}
                uploadBase64ImagesFn={() => { }}
                clearUploadedImagesFn={() => { }}
                stepToLabel={{}}
                onSubmit={(formValues) => { addProductUrlsToList(formValues.product_url); }}
            />
        </div>
    );
}


export function UploadImagePage({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    addImagesToList
}) {
    const [initialValues, setInitialValues] = useState(GetDefaultValuesFromSchema(imageUploadFormFieldsSchema));

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            <SchematicForm
                formFieldsSchema={imageUploadFormFieldsSchema}
                values={values}
                handleChange={handleChange}
                addRepeatedFieldValue={addRepeatedFieldValue}
                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                is_image_uploading={is_image_uploading}
                uploaded_images={uploaded_images}
                uploadBase64ImagesFn={uploadBase64ImagesFn}
                clearUploadedImagesFn={clearUploadedImagesFn}
                stepToLabel={{}}
                onSubmit={(formValues) => { addImagesToList(formValues.image_cdn); }}
            />
        </div>
    );
}

export function UploadVideoPage({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    addVideosToList
}) {
    const [initialValues, setInitialValues] = useState(GetDefaultValuesFromSchema(videoCdnFormFieldsSchema));

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            Test and add video upload functionality.
        </div>
    );
}