import React from "react";
// import PhotoGallery from "../components/OutfitBoards/PhotoGallery";
// import UploadPhotos from "../components/Common/UploadPhotos";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from '@mui/material/Stack';


const AuthOption = (props) => {
  const navigate = useNavigate();
  const authRespond = () => {
    if (props.access_token != undefined) {
      navigate("/logout");
    }
    else {
      navigate("/signin");
    }
  };
  return (
    <>
      <Button
        style={{
          textTransform: "none",
          justifyContent: "flex-start",
          marginTop: 6,
          marginBottom: 6,
        }}
        onClick={authRespond}
        fullWidth
      >
        <Typography variant="h6" color="primary.contrastText">{props.access_token != undefined ? "Log Out" : "Sign In"}</Typography>
      </Button>
    </>
  );
};


function NavigationOptions() {
  const navigate = useNavigate();
  return (
    <Stack spacing={3} direction="column" style={{ padding: "20px" }}>
      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: '#EAEAB3', // Darker shade of the background
          padding: '1em 2em', // Increase padding
          borderRadius: '12px', // Slightly rounded corners,
          color: 'black',
          '&:hover': {
            backgroundColor: 'rgb(236, 211, 226)' // Shade for hover
          }
        }}
        onClick={() => { navigate("/collection"); }}
      >
        Go to Collection
      </Button>
      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: '#B3D1D1', // Contrasting color
          padding: '1em 2em',
          borderRadius: '12px',
          color: 'black',
          '&:hover': {
            backgroundColor: 'rgb(236, 211, 226)' // Shade for hover
          }
        }}
        onClick={() => { navigate("/collection_insta"); }}
      >
        Update Instagram Collection
      </Button>
      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: '#EAEAB3',
          padding: '1em 2em',
          borderRadius: '12px',
          color: 'black',
          '&:hover': {
            backgroundColor: 'rgb(236, 211, 226)' // Shade for hover
          }
        }}
      >
        Curate Boards
      </Button>
    </Stack>
  );
}

function HomePage(props) {
  return (
    <>
      <Box
        style={{
          height: "100vh",
          overflow: "hidden",
          backgroundColor: "#d8bfd8"
        }}
      >
        <Box
          className="outer-box"
          style={{
            minWidth: "100%",
            minHeight: "100%",
            position: "relative",
            marginTop: "12vh",
          }}
        >
          <Container
            className="inner-container"
            component="main"
            maxWidth="sm"
            style={{
              paddingTop: "10px",
            }}
          >
            <Typography variant='h6' fontSize="1.2em" style={{ marginBottom: "20px", textAlign: "center" }}>
              {props.first_name == "" ? "Hey there, Welcome to Zive." : "Hey " + props.first_name + ", Welcome to Zive."}
            </Typography>
            <Divider sx={{ bgcolor: "primary.contrastText" }} />

            <AuthOption access_token={props.access_token} />
            <Divider sx={{ bgcolor: "primary.contrastText" }} />
            {props.access_token != undefined ? <NavigationOptions /> : <></>}

          </Container>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  access_token: state.auth.access_token,
  first_name: state.auth.first_name
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);