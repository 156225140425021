import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from 'redux';
import api from "./middleware/api";
import pictureReducer from "./pictureSlice";
import recreateReducer from "./recreateSilce";
import searchReducer from "./searchSlice";
import recreateLookReducer from "./recreateLookSlice";
import createOutfitBoardReducer from "./createOutfitBoardSlice";
import authReducer from './auth';
import uploadReducer from './upload';
import collectionsReducer from "./collections";
import updateInstaReducer from './updateInstagramInfo';
import affiliateCollectionUpdateReducer from './affiliateCollectionUpdate';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import affiliateCollectionUpdate from "./affiliateCollectionUpdate";

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['phone_number', 'access_token', 'refresh_token', 'first_name', 'last_name'],
};

const rootReducer = combineReducers({
  picture: pictureReducer,
  recreate: recreateReducer,
  search: searchReducer,
  recreateLook: recreateLookReducer,
  createOutfitBoard: createOutfitBoardReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  upload: uploadReducer,
  collections: collectionsReducer,
  collections_instagram_info: updateInstaReducer,
  affiliate: affiliateCollectionUpdateReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    var default_middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: []
      },
      immutableCheck: {
        // Ignore state paths which are large.
        ignoredPaths: []
      }
    });
    default_middleware.push(api);
    return default_middleware;
  },
});

const persistor = persistStore(store);
export { store, persistor };
