import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';


export default function ProductCard({ product_title, product_url, product_brand, product_price, product_image, isSelected, updateSelectedUrl }) {
    return (
        <Card variant="outlined" sx={{ display: 'flex', position: "relative", borderWidth: "2px", my: 1, backgroundColor: "white" }} >
            <CardMedia
                component="img"
                sx={{ width: 151, height: 151 }}
                image={product_image}
                alt={product_title}
                onClick={() => {
                    console.log("Product clicked")
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', width: "100%" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "80%" }}>
                    <CardContent sx={{ flex: '1 0 auto' }}
                        onClick={() => {
                            console.log("Product clicked")
                        }}>
                        <Typography component="div" variant="subtitle2">
                            {product_title}
                        </Typography>
                        <Typography variant="body1" component="div" style={{ fontSize: "0.6em" }}>
                            {product_brand}
                        </Typography>
                        <Typography variant="body1" component="div">
                            Rs {product_price}
                        </Typography>
                    </CardContent>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: "20%" }}>
                    <Checkbox checked={isSelected == 1} onChange={(e) => { updateSelectedUrl(e.target.checked) }} />
                </Box>
            </Box>

        </Card>
    );
}