import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage } from "./../Controllers/Notifications";
import { AuthUrls } from "../Constants";

const slice = createSlice({
    name: "auth",
    initialState: {
        access_token: null,
        refresh_token: null,
        identifier: null,
        phone_number: '',
        name_updated: false,
        password_updated: false,
        otp_verified: false,
        first_name: '',
        last_name: '',
        show_swipe_instruction: true
    },
    reducers: {
        // action => action handler
        loginUserStarted: (state, action) => {
        },
        loginUserSuccess: (state, action) => {
            state.phone_number = action.payload.data.phone_number;
            state.access_token = action.payload.server_response.access;
            state.refresh_token = action.payload.server_response.refresh;
            state.first_name = action.payload.server_response.first_name;
            state.last_name = action.payload.server_response.last_name;
            SuccessMessage("Logged In successfully.");
        },
        loginUserFailed: (state, action) => {
            if (action.payload && action.payload.server_response && action.payload.server_response.detail != undefined) {
                ErrorMessage(action.payload.server_response.detail);
            }
            else if (action.payload && action.payload.server_response && action.payload.server_response.reason != undefined) {
                ErrorMessage(action.payload.server_response.reason);
            }
            else {
                ErrorMessage("Log In Failed");
            }
        },
        signUpUserStarted: (state, action) => {
        },
        signUpUserSuccess: (state, action) => {
            state.phone_number = action.payload.data.phone_number;
            // We set the otp here, since we use this later when setting passwords.
            state.otp = action.payload.data.otp;
            state.access_token = action.payload.server_response.access;
            state.refresh_token = action.payload.server_response.refresh;
            SuccessMessage("Registration Successful.")
        },
        signUpUserFailed: (state, action) => {
            if (action.payload && action.payload.server_response && action.payload.server_response.reason != undefined) {
                ErrorMessage(action.payload.server_response.reason);
            }
            else {
                ErrorMessage("Internal registration error.");
            }
        },
        logoutUserAction: (state, action) => {
            state.access_token = null;
            state.refresh_token = null;
            state.phone_number = '';
            state.name_updated = false;
            state.password_updated = false;
            state.otp_verified = false;
            state.first_name = '';
            state.last_name = '';
            if (action.payload.reload) {
                window.location.reload();
            }
        },
        setRefreshedAccessToken: (state, action) => {
            state.access_token = action.payload.access_token;
        },
        setIdentifier: (state, action) => {
            // This is the unique identifier set, so we can identify users who are not yet logged in.
            state.identifier = action.payload.identifier;
        },
        verifyOtpStarted: (state, action) => {
        },
        verifyOtpSuccess: (state, action) => {
            SuccessMessage("OTP Verified");
            state.otp_verified = true;
            state.otp = action.payload.data.otp;
        },
        verifyOtpFailed: (state, action) => {
            ErrorMessage("Failed to verify OTP");
        },
        onResetPasswordStarted: (state, action) => {
        },
        onResetPasswordSuccess: (state, action) => {
            SuccessMessage("Password changed successfully.");
            state.password_updated = true;
        },
        onResetPasswordFailed: (state, action) => {
            if (action.payload && action.payload.server_response && action.payload.server_response.reason != undefined) {
                ErrorMessage(action.payload.server_response.reason);
            }
            else {
                ErrorMessage("Error updating password");
            }
        },
        onChangePasswordStarted: (state, action) => {
        },
        onChangePasswordSuccess: (state, action) => {
            SuccessMessage("Passord changed successfully.");
        },
        onChangePasswordFailed: (state, action) => {
            if (action.payload && action.payload.server_response && action.payload.server_response.reason != undefined) {
                ErrorMessage(action.payload.server_response.reason);
            }
            else {
                ErrorMessage("Error updating password");
            }
        },
        onRequestOtpStarted: (state, action) => {
        },
        onRequestOtpSuccess: (state, action) => {
            SuccessMessage('OTP sent.');
        },
        onRequestOtpFailed: (state, action) => {
            if (action.payload && action.payload.server_response && action.payload.server_response.reason != undefined) {
                ErrorMessage(action.payload.server_response.reason);
            }
            else {
                ErrorMessage("Error requesting OTP");
            }
        },
        onNameChangeStarted: (state, action) => {
        },
        onNameChangeSuccess: (state, action) => {
            state.name_updated = true;
            state.first_name = action.payload.data.first_name;
            state.last_name = action.payload.data.last_name;
            state.name_updated = true;
            SuccessMessage('Name updated successfully.');
        },
        onNameChangeFailed: (state, action) => {
            if (action.payload && action.payload.server_response && action.payload.server_response.reason != undefined) {
                ErrorMessage(action.payload.server_response.reason);
            }
            else {
                ErrorMessage("Error updating name");
            }
        },
        updateSwipeInstruction: (state, action) => {
            state.show_swipe_instruction = false;
        },
    }
});

export const {
    loginUserStarted,
    loginUserSuccess,
    loginUserFailed,
    signUpUserStarted,
    signUpUserSuccess,
    signUpUserFailed,
    logoutUserAction,
    setRefreshedAccessToken,
    verifyOtpStarted,
    verifyOtpSuccess,
    verifyOtpFailed,
    onResetPasswordStarted,
    onResetPasswordSuccess,
    onResetPasswordFailed,
    onChangePasswordStarted,
    onChangePasswordSuccess,
    onChangePasswordFailed,
    onRequestOtpStarted,
    onRequestOtpSuccess,
    onRequestOtpFailed,
    onNameChangeStarted,
    onNameChangeSuccess,
    onNameChangeFailed,
    updateSwipeInstruction
} = slice.actions;


export default slice.reducer;

export const loginUser = (formValues) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: AuthUrls.LOGIN,
            data_to_server: formValues,
            data: { phone_number: formValues['phone_number'] },
            method: "post",
            onStart: loginUserStarted.type,
            onSuccess: loginUserSuccess.type,
            onError: loginUserFailed.type
        })
    );
};

export const registerUser = (formValues) => (dispatch, getState) => {
    formValues['username'] = formValues['phone_number'];
    return dispatch(
        apiCallBegan({
            url: AuthUrls.REGISTER,
            data_to_server: formValues,
            data: { phone_number: formValues['phone_number'], 'otp': formValues['otp'] },
            method: "post",
            onStart: signUpUserStarted.type,
            onSuccess: signUpUserSuccess.type,
            onError: signUpUserFailed.type,
        })
    );
};

export const logoutUser = () => (dispatch, getState) => {
    return dispatch({ type: logoutUserAction.type, payload: {} });
};

export const updateSwipeInstructionFn = () => (dispatch, getState) => {
    return dispatch({ type: updateSwipeInstruction.type, payload: {} });
};

export const submitOtp = (otp) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: AuthUrls.VERIFY_OTP,
            data_to_server: {
                'otp': otp,
                'phone_number': getState().auth.phone_number
            },
            data: {
                'otp': otp,
            },
            method: "post",
            onStart: verifyOtpStarted.type,
            onSuccess: verifyOtpSuccess.type,
            onError: verifyOtpFailed.type
        })
    );
};

export const otpLoginUser = (formValues) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: AuthUrls.OTP_LOGIN,
            data_to_server: formValues,
            data: {
                'phone_number': formValues['phone_number']
            },
            method: "post",
            onStart: loginUserStarted.type,
            onSuccess: loginUserSuccess.type,
            onError: loginUserFailed.type
        })
    );
};

export const requestOtp = (formValues, is_login) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: is_login ? AuthUrls.REQUEST_OTP : AuthUrls.REQUEST_REGISTER_OTP,
            data_to_server: formValues,
            data: {
            },
            method: "post",
            onStart: onRequestOtpStarted.type,
            onSuccess: onRequestOtpSuccess.type,
            onError: onRequestOtpFailed.type,
        })
    );
};

export const resetPassword = (formValues) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: AuthUrls.RESET_PASSWORD,
            data_to_server: formValues,
            method: "post",
            onStart: onResetPasswordStarted.type,
            onSuccess: onResetPasswordSuccess.type,
            onError: onResetPasswordFailed.type,
        })
    );
};


export const changePassword = (formValues) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: AuthUrls.CHANGE_PASSWORD,
            data_to_server: formValues,
            method: "post",
            onStart: onChangePasswordStarted.type,
            onSuccess: onChangePasswordSuccess.type,
            onError: onChangePasswordFailed.type,
        })
    );
};

export const updateFirstAndLastName = (formValues) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: AuthUrls.UPDATE_NAME,
            data_to_server: formValues,
            data: { 'first_name': formValues['first_name'], 'last_name': formValues['last_name'] },
            method: "post",
            onStart: onNameChangeStarted.type,
            onSuccess: onNameChangeSuccess.type,
            onError: onNameChangeFailed.type,
        })
    );
};

export const unifiedRequestOtp = (phone_number) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: '/auth/request_ulogin_otp/',
            data_to_server: { 'phone_number': phone_number },
            data: {},
            method: "post",
            onStart: onRequestOtpStarted.type,
            onSuccess: onRequestOtpSuccess.type,
            onError: onRequestOtpFailed.type,
        })
    );
};

export const unifiedOtpVerify = (phone_number, otp) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: '/auth/verify_ulogin_otp/',
            data_to_server: { 'phone_number': phone_number, 'otp': otp },
            data: {},
            method: "post",
            onStart: loginUserStarted.type,
            onSuccess: loginUserSuccess.type,
            onError: loginUserFailed.type,
        })
    );
};