var items = require("../data/Items.json");
var recreate = require("../data/recreate.json");
var search_results = require("../data/searchResults.json");
var outfit_board_query_list_with_hashtags = require("../data/outfitBoardQueryListWithHashtags");
var product_urls_for_search_query = require("../data/productUrlsForSearchQuery.json");
var collections_list = require("../data/templates.json");

function fetchItems(items, startFrom, numToFetch) {
  const fetchedItems = [];

  for (let i = startFrom; i < items.length && fetchedItems.length < numToFetch; i++) {
    fetchedItems.push(items[i]);
  }

  return fetchedItems;
}

function getJSONById(id, jsonList) {
  for (let i = 0; i < jsonList.length; i++) {
    if (jsonList[i].id === id) {
      return jsonList[i];
    }
  }
  return null; // Return null if the id is not found
}

function get_mock_response(url, method, data) {
  if (url === "/fetch_scored_search_response") {
    console.log("Fetch search results for category", data.category);
    console.log(method);
    var response = {
      data: {
        results: search_results,
      },
      status: 200,
    };
    return response;
  } else if (url === "/get_outfit_board_with_unique_name") {
    console.log("Fetch outfit board for", data.unique_name);
    var response = {
      data: {
        results: recreate,
      },
      status: 200,
    }; return response;
  } else if (url === "/recreateLook") {
    // var products = items.filter((item) => item.unique_name == id);
    var response = {
      data: {
        objectCopy: JSON.parse(JSON.stringify(recreate)),
        // objectCopy: items,
      },
    };
    // var objectCopy = JSON.parse(JSON.stringify(items));

    // console.log(objectCopy);
    return response;
  } else if (url === "/sendInfo") {
    console.log("data To Backend", data);
    var response = { data: data };

    return response;
  }
  else if (url == "/outfitBoardQueryListWithHashtags") {
    var response = {
      data: {
        outfitBoardQueryListWithHashtags: JSON.parse(
          JSON.stringify(outfit_board_query_list_with_hashtags)
        ),
      },
    };
    return response;
  } else if (url == "/search/query") {
    var response = {
      data: {
        productUrlsForSearchQuery: JSON.parse(
          JSON.stringify(product_urls_for_search_query)
        ),
      },
    };
    return response;
  }
  else if (url == "/fetch_user_collections/") {
    var response = {
      data: {
        collections_list: fetchItems(collections_list, data.start_from, data.num_to_fetch)
      },
    };
    return response;
  }
  else if (url == "/fetch_collection_with_id/") {
    console.log("Reached here.");
    var response = {
      data: {
        collection: getJSONById(data.id, collections_list)
      },
    };
    return response;
  }
  else if (url == "/create_new_collection/") {
    console.log("collection info:", data.info);
    var response = {
      data: {
        collection_id: "new_collection"
      },
    };
    return response;
  }
  else if (url == "/update_collection/") {
    console.log("attempt to save the collection with info:", data.info);
    var response = {
      data: {},
    };
    return response;
  }
  else {
    throw "Undefined url.";
  }
}

export function api_mock(url, method, data) {
  return new Promise((resolve) => {
    // wait 3s before calling fn(par)
    setTimeout(() => resolve(get_mock_response(url, method, data)), 1000);
  });
}
