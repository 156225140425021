import React from "react";
import MediaCollectionCard from "./MediaCollectionCard";
import ProductCollectionCard from "./ProductCollectionCard";

function ItemCard(props) {
  const { item, showEditOptions } = props.props;
  return (
    <div>
      {item.product_details === undefined ? (
        <MediaCollectionCard props={{ item, showEditOptions }} />
      ) : (
        <ProductCollectionCard props={{ item, showEditOptions }} />
      )}
    </div>
  );
}

export default ItemCard;
