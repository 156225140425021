import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage, WarningMessage } from "../Controllers/Notifications";

const slice = createSlice({
    name: "collections_instagram_info",
    initialState: {
        collections_list: [],
    },
    reducers: {
        fetchUserCollectionsStarted: (state, action) => { },
        fetchUserCollectionsSuccess: (state, action) => {
            var items = state.collections_list;
            if (action.payload.data.start_from >= items.length) {
                if (action.payload.server_response.collections_list.length == 0) {
                    WarningMessage("No more collections found");
                }
                else {
                    items.push(...action.payload.server_response.collections_list);
                    state.collections_list = items;
                }
            }
        },
        fetchUserCollectionsFailed: (state, action) => {
            ErrorMessage("Error fetching the collections");
        },
    },
});

export const {
    fetchUserCollectionsStarted,
    fetchUserCollectionsSuccess,
    fetchUserCollectionsFailed,
} = slice.actions;

export default slice.reducer;

export const fetchUserCollections =
    (start_from, num_to_fetch) => (dispatch, getState) => {
        return dispatch(
            apiCallBegan({
                url: "/creator/fetch_user_collections_for_insta_update/",
                data_to_server: { start_from: start_from, num_to_fetch: num_to_fetch },
                data: { start_from: start_from, num_to_fetch: num_to_fetch },
                method: "post",
                onStart: fetchUserCollectionsStarted.type,
                onSuccess: fetchUserCollectionsSuccess.type,
                onError: fetchUserCollectionsFailed.type,
            })
        );
    };