import React, { useEffect, useRef, useState } from "react";

function ColorPicker({ image_url, pickColorModalRef, setPickedColor }) {
  const canvas = useRef(null);
  const color = useRef(null);
  const [colorcode, setColorcode] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [values, setValues] = useState({
    x: window.innerWidth / 8,
    y: window.innerHeight / 8,
    targetX: 0,
    targetY: 0,
  });

  const { x, y, targetX, targetY } = values;
  const [coordinate, setCoordinate] = useState({
    xcoordinate: 0,
    ycoordinate: 0,
  });

  useEffect(() => {
    if (canvas) {
      const img = new Image();
      img.src = image_url;
      const ctx = canvas.current.getContext("2d");
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 400, 400);
      };
      img.crossOrigin = "Anonymous";

      setValues({
        ...values,
        x: x + canvas.current.offsetLeft,
        y: y + canvas.current.offsetTop,
      });
    }
  }, [canvas]);
  const convertToHex = (value) => {
    var hex = value.toString(16);
    if (value < 16) {
      hex = "0" + hex;
    }
    return hex;
  };

  const mouseMove = (e) => {
    setValues({
      ...values,
      targetX: e.pageX - window.innerWidth / 8,
      targetY: e.pageY - window.innerHeight / 8,
    });
    setCoordinate({ xcoordinate: targetX, ycoordinate: targetY });
    const context = e.target.getContext("2d");
    const pixelData = context.getImageData(targetX, targetY, 1, 1).data;
    const hex =
      "#" +
      convertToHex(pixelData[0]) +
      convertToHex(pixelData[1]) +
      convertToHex(pixelData[2]);
    setColorcode(hex);
    color.current.style.backgroundColor = hex;
  };

  return (
    <div className="h-full w-full lg:flex lg:space-x-8">
      <div className="">
        <canvas
          id="canvas"
          ref={canvas}
          width={400}
          height={400}
          onMouseMove={mouseMove}
          onClick={() => {
            navigator.clipboard.writeText(colorcode);
            setSelectedColor(colorcode);
          }}
          className=" cursor-crosshair rounded-xl"
        />
        <div className="font-bold text-xl lg:text-3xl absolute top-0 right-0 m-4">
          Pick Color
        </div>

        <div className="p-4 m-2 flex  items-center space-x-2">
          <div ref={color} className="w-8 h-8 rounded-md"></div>
          <div className="text-black text-xl font-bold">{colorcode}</div>
        </div>
      </div>
      <div className="h-full w-full  flex m-4 justify-center">
        <div className="">
          <h1 className="font-semibold text-2xl mb-1.5">Color Selected</h1>
          {selectedColor ? (
            <div className="flex space-x-2 justify-center">
              <div
                style={{
                  backgroundColor: `${selectedColor}`,
                }}
                className="w-8 h-8 rounded-md "
              ></div>
              <h2
                className="font-bold text-lg"
              >
                {selectedColor}
              </h2>
            </div>
          ) : (
            <div className="font-bold text-lg">none</div>
          )}

          <div
            className="mt-4 text-xl font-semibold bg-blue-500 flex justify-center p-1.5 rounded-full hover:opacity-90 text-white cursor-pointer "
            onClick={() => {
              setPickedColor(selectedColor);
              if (pickColorModalRef) {
                pickColorModalRef.current.close();
              }
            }}
          >
            Done
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
