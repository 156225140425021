import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import EditCollectionsInfo from './EditCollectionsInfo';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CollectionItemList from "./DisplayCollectionItems";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import InstagramInfo from './InstagramInfo';
import DownloadButton from './DownloadImagesButton';
import WishlinkCreationModal from './WishlinkCreationModal';

const DEFAULT_IMAGE = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJdoo2ir60MPwYQnzx9uHNgqNCam0B4-58dnnqsOLeNw&s"


const styles = {
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '1rem',
        maxHeight: '100px',
        overflow: 'hidden',
        backgroundColor: 'lightgrey',
        marginLeft: "2%",
        marginRight: "2%"
    },
    image: {
        flex: '0 0 auto',
        width: 'auto',
        maxHeight: '100px',
        marginRight: '1rem',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    button: {
        position: 'absolute',
        top: '0.5rem',
        right: '0.5rem',
    },
};

function DisplayCollectionInfo({ visible_name, subtitle, status, media, onEditFn, disableEdits }) {
    return (
        <Card style={styles.root}>
            <CardMedia
                component="img"
                style={styles.image}
                height="100"
                image={media.representative_image ? media.representative_image : DEFAULT_IMAGE}
                alt="Representative Image"
            />
            <CardContent style={styles.cardContent}>
                <Typography variant="h6">{visible_name}</Typography>
                <Typography variant="body1" gutterBottom>
                    {subtitle}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Status: {status}
                </Typography>
                <Button style={styles.button} variant="outlined" onClick={onEditFn} disabled={disableEdits}>
                    Edit
                </Button>
            </CardContent>
        </Card>
    );
}

function EditCollectionInfoHelper({
    visible_name,
    subtitle,
    status,
    representative_image,
    access_type,
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    onSubmitFn
}) {
    return (
        <EditCollectionsInfo
            is_image_uploading={is_image_uploading}
            uploaded_images={uploaded_images}
            uploadBase64ImagesFn={uploadBase64ImagesFn}
            clearUploadedImagesFn={clearUploadedImagesFn}
            initCollectionInfo={{
                visible_name: visible_name,
                subtitle: subtitle,
                status: status,
                access_type: access_type,
                representative_image: representative_image == "" ? [] : [representative_image]
            }}
            onSubmitFn={(values) => {
                onSubmitFn(values);
                // console.log("Updated structure", values);
            }}>
        </EditCollectionsInfo>
    );
}

const ImageDownloadComponent = ({ item_ids, folder_name }) => {
    const [images, setImages] = useState([]);
    useEffect(() => {
        var imageUrls = [];
        item_ids.forEach((item_id, index) => {
            if (item_id && item_id.item_status == 'ACTIVE' && item_id.item_content && item_id.item_content.board_image_url && item_id.item_content.board_image_url.length > 0) {
                const imageUrl = item_id.item_content.board_image_url[0];
                imageUrls.push(imageUrl);
            }
        });
        setImages(imageUrls);
    }, [item_ids]);

    const handleImageClick = (imageUrl, imageName) => {
        window.open(imageUrl, '_blank');
    };

    return (
        <div style={{ display: 'flex', marginTop: "30px" }}>
            {images.map((imageUrl, index) => (
                <img
                    key={index}
                    src={imageUrl}
                    alt={`Image ${index + 1}`}
                    style={{ cursor: 'pointer', maxHeight: '70px', marginRight: '10px' }}
                    onClick={() => handleImageClick(imageUrl, `image_${index + 1}.jpg`)}
                />
            ))}
        </div>
    );
};


const DisplayOrEditCollectionInfo = ({
    state,
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    updateCollection,
    publishOutfitBoardCollection,
    submitSocialInfo,
    finalizePost,
    saveWishlinks,
    unPublishCollection
}) => {
    const { item_ids, outfit_features, social_metadata, ...rest } = state;
    const [contentState, setContentState] = useState(rest);
    const [updatedItems, setUpdatedItems] = useState([...item_ids].sort((a, b) => b.scores.display_score - a.scores.display_score));
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const navigate = useNavigate();

    const updateContentState = (visible_name, subtitle, status, representative_image, access_type) => {
        const updatedMedia = representative_image.length > 0 ? representative_image[0] : '';

        setContentState(prevState => ({
            ...prevState,
            visible_name: visible_name,
            subtitle: subtitle,
            status: status,
            access_type: access_type,
            media: {
                ...prevState.media,
                representative_image: updatedMedia
            }
        }));
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function publishCollection() {
        var collection_info_with_item_ids = contentState;
        collection_info_with_item_ids['item_ids'] = updatedItems;
        updateCollection(collection_info_with_item_ids);
        setHasUnsavedChanges(false);
    }

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between" style={{ padding: "10px" }}>
                <Grid item >
                    <Button style={{
                        color: "black",
                        backgroundColor: "#aaaaa",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                        variant="outlined" onClick={() => {
                            navigate('/collection/')
                        }}>
                        Back
                    </Button>
                </Grid>
                <Grid item >
                    {hasUnsavedChanges &&
                        <Typography variant="h6" style={{ color: 'red' }} gutterBottom>
                            You have unsaved changes. Please Save them.
                        </Typography>
                    }
                    {!contentState.is_editable && contentState.creation_status == "PUBLISHED" &&
                        <Typography variant="h6" style={{ color: 'orange' }} gutterBottom>
                            This collection was published and base content cannot be edited.
                        </Typography>
                    }
                    {!contentState.is_editable && contentState.creation_status == "POSTED" &&
                        <Typography variant="h6" style={{ color: 'orange' }} gutterBottom>
                            This collection was published and content cannot be edited. Only insta info can be updated.
                        </Typography>
                    }
                </Grid>
                {contentState.creation_status == "POSTED" && <Grid item >
                    <Button style={{
                        color: "black",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                        variant="outlined" onClick={() => {
                            unPublishCollection(contentState.id);
                        }}>
                        UnPublish
                    </Button>
                </Grid>
                }
                <Grid item >
                    <Button style={{
                        color: "black",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                        disabled={contentState.is_editable != undefined ? !contentState.is_editable : true}
                        variant="outlined" onClick={() => {
                            publishOutfitBoardCollection(contentState.id);
                        }}>
                        Publish Outfit Collection
                    </Button>
                </Grid>
                <Grid item >
                    <Button style={{
                        color: "black",
                        marginTop: "10px",
                        marginBottom: "10px",
                    }}
                        disabled={contentState.creation_status == "POSTED"}
                        variant="outlined" onClick={() => {
                            finalizePost();
                        }}>
                        Finalize Post
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        style={{
                            color: "white",
                            backgroundColor: "#FF6961",
                            marginTop: "10px",
                            marginBottom: "10px",
                        }}
                        disabled={contentState.creation_status == "POSTED"}
                        variant="contained" onClick={() => {
                            publishCollection()
                        }}>
                        Save Changes
                    </Button>
                </Grid>
            </Grid>
            <DisplayCollectionInfo
                visible_name={contentState.visible_name}
                subtitle={contentState.subtitle}
                status={contentState.status}
                media={contentState.media}
                disableEdits={contentState.is_editable != undefined ? !contentState.is_editable : true}
                onEditFn={() => {
                    handleOpen();
                }}
            />
            <Modal open={open} onClose={handleClose}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <IconButton sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <EditCollectionInfoHelper
                        visible_name={contentState.visible_name}
                        subtitle={contentState.subtitle}
                        status={contentState.status}
                        representative_image={contentState.media.representative_image}
                        access_type={contentState.access_type}
                        is_image_uploading={is_image_uploading}
                        uploaded_images={uploaded_images}
                        uploadBase64ImagesFn={uploadBase64ImagesFn}
                        clearUploadedImagesFn={clearUploadedImagesFn}
                        onSubmitFn={(updated_values) => {
                            updateContentState(
                                updated_values.visible_name,
                                updated_values.subtitle,
                                updated_values.status,
                                updated_values.representative_image,
                                updated_values.access_type
                            )
                            handleClose();
                            setHasUnsavedChanges(true);
                        }}
                    />
                </Box>
            </Modal>
            <Box display="flex" justifyContent="space-between" width="100%" p={2}>
                <Box width="30%" p={2} >
                    <InstagramInfo social_metadata={social_metadata} submitSocialInfo={submitSocialInfo} />
                </Box>
                <Box width="30%" p={2} >
                    <WishlinkCreationModal
                        wishlink_mapping={Array.isArray(state.wishlink_mapping) ? state.wishlink_mapping : []}
                        saveWishlinkFn={saveWishlinks}
                        collection_id={rest.id}
                    />
                </Box>
                <Box width="30%" p={2} >
                    <DownloadButton collection_id={contentState.id} />
                </Box>
            </Box>
            {/* <ImageDownloadComponent item_ids={item_ids} folder_name={contentState.id} /> */}
            <CollectionItemList
                updatedItems={updatedItems}
                setUpdatedItems={setUpdatedItems}
                setHasUnsavedChanges={setHasUnsavedChanges}
                disableEdits={contentState.is_editable != undefined ? !contentState.is_editable : true}
                outfitFeatures={outfit_features}
                creationStatus={contentState.creation_status}
            ></CollectionItemList>
        </>
    );
};

export default DisplayOrEditCollectionInfo;