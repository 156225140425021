import React, { useEffect, useState } from "react";
import { getOutfitBoardQueryListWithHashtags } from "../../store/createOutfitBoardSlice";
import { connect } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import SelectProductsForOutfitBoard from "../../components/OutfitBoards/SelectProductsForOutfitBoard";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function QueryTripletFeedback({ value, handleFeedbackUpdate }) {
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value ? value : "na"}
                onChange={handleFeedbackUpdate}
            >
                <FormControlLabel value="good" control={<Radio />} label="Good" />
                <FormControlLabel value="bad" control={<Radio />} label="Bad" />
            </RadioGroup>
        </FormControl>
    );
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));


const OutfitBoards = (props) => {
    const handleSelectIndex = (selectedIndex) => {
        props.setSelectedIndex(selectedIndex);
    };

    const handleChange = (index, event) => {
        var tripletFeedbackCpy = [...props.tripletFeedback];
        if (event.target.value == 'good') {
            tripletFeedbackCpy[index] = 'good';
        }
        else if (event.target.value == 'bad') {
            tripletFeedbackCpy[index] = 'bad';
        }
        else {
            ;
        }
        if (event.target.value == "good" || event.target.value == "bad") {
            console.log("Register query level feedback:", index, event.target.value, props.outfitBoardQueryList[index].unique_name, Date.now());
        }
        props.setTripletFeedback(tripletFeedbackCpy);
    };

    const response =
        props.outfitBoardQueryList?.length > 0 ? (
            <div style={{ overflow: "auto", height: parseInt((0.85) * window.innerHeight) }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Row</StyledTableCell>
                                <StyledTableCell align="right">tops</StyledTableCell>
                                <StyledTableCell align="right">bottoms</StyledTableCell>
                                <StyledTableCell align="right">outerwear</StyledTableCell>
                                <StyledTableCell align="right">dresses</StyledTableCell>
                                <StyledTableCell align="right">footwear</StyledTableCell>
                                <StyledTableCell align="right">bag</StyledTableCell>
                                <StyledTableCell align="right">accessories</StyledTableCell>
                                <StyledTableCell align="right">create</StyledTableCell>
                                <StyledTableCell align="right">feedback</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.outfitBoardQueryList.map((outfitBoardQuery, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">
                                        {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.tops[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.bottoms[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.outerwear[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.dresses[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.footwear[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.bag[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {outfitBoardQuery.queries?.accessories[0] ?? ""}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button
                                            variant="contained"
                                            color={
                                                props.selectedIndex === index ? "secondary" : "primary"
                                            }
                                            disabled={props.tripletFeedback[index] != "good"}
                                            onClick={() => handleSelectIndex(index)}
                                        >
                                            Create
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <QueryTripletFeedback value={props.tripletFeedback && props.tripletFeedback[index] ? props.tripletFeedback[index] : "na"} handleFeedbackUpdate={(event) => { handleChange(index, event) }} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        ) : (
            ""
        );
    return response;
};


function CreateOutfitBoardPage({
    getOutfitBoardQueryListWithHashtags,
    outfitBoardQueryListWithHashtags,
}) {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [tripletFeedback, setTripletFeedback] = useState(new Array(outfitBoardQueryListWithHashtags.length).fill("na"));

    useEffect(() => {
        // This function extracts the url parms of the form "?hashtags=abc,def" into a list of different hashtags.
        // It then calls getOutfitBoardQueryListWithHashtags to get the relevant outfit board queries for the list of hashtags.
        const extractHashTagsAndPopulateData = async () => {
            const { search } = window.location;
            if (search) {
                const searchParams = new URLSearchParams(search);
                let hashtags = searchParams.get("hashtags");
                if (hashtags) {
                    hashtags = hashtags.split(",").map((tag) => tag.trim());
                    getOutfitBoardQueryListWithHashtags(hashtags);
                }
            }
        };
        extractHashTagsAndPopulateData();
    }, [getOutfitBoardQueryListWithHashtags]);


    return (
        <div className="">
            {selectedIndex === -1 ? (
                <OutfitBoards
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    outfitBoardQueryList={outfitBoardQueryListWithHashtags}
                    tripletFeedback={tripletFeedback}
                    setTripletFeedback={setTripletFeedback}
                />
            ) : (
                <></>
            )}
            {selectedIndex >= 0 ? (
                <SelectProductsForOutfitBoard
                    toggleToMainFn={() => { setSelectedIndex(-1) }}
                    //selectedIndex={selectedIndex}
                    outfitBoardQuery={outfitBoardQueryListWithHashtags[selectedIndex]}
                />
            ) : (
                <></>
            )}
        </div>
    );
}


const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    outfitBoardQueryListWithHashtags:
        state.createOutfitBoard.outfitBoardQueryListWithHashtags,
});


const mapDispatchToProps = (dispatch) => ({
    getOutfitBoardQueryListWithHashtags: (hashtags) =>
        dispatch(getOutfitBoardQueryListWithHashtags(hashtags)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOutfitBoardPage);