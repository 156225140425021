import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const createOutfitBoardSlice = createSlice({
    name: "createOutfitBoard",
    initialState: {
        hashtags: [],
        outfitBoardQueryListWithHashtags: [],
    },
    reducers: {
        getOutfitBoardQueryListWithHashtagsStarted: (state, action) => { },
        getOutfitBoardQueryListWithHashtagsSuccess: (state, action) => {
            state.outfitBoardQueryListWithHashtags =
                action.payload.server_response.response;
            state.hashtags = action.payload.data.hashtags;
        },
        getOutfitBoardQueryListWithHashtagsFailed: (state, action) => { },
    },
});


export const {
    getOutfitBoardQueryListWithHashtagsStarted,
    getOutfitBoardQueryListWithHashtagsSuccess,
    getOutfitBoardQueryListWithHashtagsFailed,
} = createOutfitBoardSlice.actions;


export default createOutfitBoardSlice.reducer;


//Gets an outfit board with a given list of hashTags.
//The outfit board is expected to have a uniqueName and set of queries for each Component.
export const getOutfitBoardQueryListWithHashtags =
    (hashtags) => (dispatch, getState) => {
        console.log("getOutfitBoardQueryListWithHashtags called", hashtags);
        var hashtags_arr_processed = new Array();
        for (var index = 0; index < hashtags.length; index++) {
            hashtags_arr_processed.push("#" + hashtags[index])
        }
        return dispatch(
            apiCallBegan({
                url: "/lookbook/get_outfit_boards_with_hashtags/",
                method: "post",
                data_to_server: { hashtags: hashtags_arr_processed },
                data: { hashtags: hashtags },
                onStart: getOutfitBoardQueryListWithHashtagsStarted.type,
                onSuccess: getOutfitBoardQueryListWithHashtagsSuccess.type,
                onFailed: getOutfitBoardQueryListWithHashtagsFailed.type,
            })
        );
    };