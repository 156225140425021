import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from 'react';

function ImageBox(props) {
    return (
        <Box
        >
            <Box
                style={{
                    height: "100%",
                    height: "220px",
                    backgroundImage: `url(${props.content.image_path})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}>
            </Box>
            {props.content.text && <Box style={{
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                paddingLeft: "5px",
                paddingRight: "5px",
                paddingTop: "2px",
                paddingBottom: "2px",
            }}>
                <Typography style={{ color: 'white' }}>
                    {props.content.text}
                </Typography>
            </Box>}
        </Box>
    );
}

function GetImageContentForRow(content, row_number, column_number, num_row, num_column) {
    let index_in_images = row_number * num_column + column_number;
    if (index_in_images < content.length) {
        return content[index_in_images];
    }
    else {
        return "";
    }
}

function ImageRow(props) {
    return (
        <Grid container
            spacing={0}
            style={{
                width: "100%",
                height: "100%",
                flexDirection: "row",
            }}>
            {
                Array(props.num_row).fill(null).map((_, i) => i).map((item, index) => (
                    <Grid item xs={12} key={index}>
                        {
                            GetImageContentForRow(props.images, index, props.column_number, props.num_row, props.num_column) == ""
                                ? <div></div>
                                :
                                <ImageBox
                                    content={GetImageContentForRow(props.images, index, props.column_number, props.num_row, props.num_column)}
                                >
                                </ImageBox>
                        }
                    </Grid>
                ))
            }
        </Grid>
    );
}

function GetNumRowsAndColumns(images) {
    if (images.length <= 2) {
        return {
            num_row: 1,
            num_column: 2
        }
    } else if (images.length <= 4) {
        return {
            num_row: 2,
            num_column: 2
        }
    }
    else if (images.length <= 6) {
        return {
            num_row: 3,
            num_column: 2
        }
    }
    else if (images.length <= 9) {
        return {
            num_row: 3,
            num_column: 2
        }
    }
}

function ImageGrid(props) {
    const { num_row, num_column } = GetNumRowsAndColumns(props.images);
    return (
        <Grid
            container
            spacing={1}
            style={{
                width: "100%",
                margin: "-4px"
            }}
        >
            {
                Array(num_column).fill(null).map((_, i) => i).map((item, index) => (
                    <Grid item key={index} xs={parseInt(12 / num_column)}>
                        <ImageRow
                            images={props.images}
                            column_number={index}
                            num_column={num_column}
                            num_row={num_row}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default ImageGrid;