import React, { useState, useEffect } from "react";
import {
    useNavigate,
    useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import {
    loginUser,
    logoutUser,
    unifiedOtpVerify,
    unifiedRequestOtp,
} from "../../store/auth";
import { updateFirstAndLastName } from "../../store/auth";

import PhoneNumberPage from "../../components/Signin/PhoneNumberPage";
import OTPPage from "../../components/Signin/OTPPage";
import PasswordPage from "../../components/Signin/PasswordPage";
import UpdateFirstAndLastName from "../../components/Signin/UpdateFirstAndLastName";

//MUI
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";


function Auth(props) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [userOtp, setUserOtp] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [pageState, setPageState] = useState('home');

    function navigateToReferrer() {
        navigate(from, { replace: true });
    }

    useEffect(() => {
        // When authenticated and access token is set, but name is empty, we show the name screen.
        if (props.access_token != undefined && (pageState == 'otp' || pageState == 'password') && (props.first_name == '' && props.last_name == '')) {
            setPageState('update_name');
        }
        else if (props.access_token != undefined && (props.first_name != '' || props.last_name != '')) {
            // When authenticated and access token is set, and name is also set, we navigate to referrer.
            //  If name if already set, we directly navigate to referrer.
            if (props.close_drawer_fn != undefined) {
                props.close_drawer_fn();
            }
            else {
                navigateToReferrer();
            }

            setPageState('home');
        }
        else if (props.access_token != undefined) {
            // If not other condition matches and access token was defined, lets redirect.
            if (props.close_drawer_fn != undefined) {
                props.close_drawer_fn();
            }
            else {
                navigateToReferrer();
            }
        }
    }, [props.access_token, props.first_name, props.last_name]);

    const requestOtp = () => {
        props.unifiedRequestOtp(userPhoneNumber);
    };

    const handleSubmit = (e) => {
        if (pageState == 'otp') {
            props.unifiedOtpVerify(userPhoneNumber, userOtp);
        } else if (pageState == 'password') {
            props.loginUser({
                phone_number: userPhoneNumber,
                password: userPassword,
            });
        }
    };

    if (pageState == 'update_name') {
        return (
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 12,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5" style={{ marginBottom: "2em" }}>
                        Please tell us your name.
                    </Typography>
                    <UpdateFirstAndLastName updateFirstAndLastName={props.updateFirstAndLastName} />
                    <Button
                        style={{
                            borderRadius: "2em",
                            textTransform: "none",
                            justifyContent: "center",
                            marginTop: "2em",
                        }}
                        fullWidth
                        onClick={() => {
                            if (props.close_drawer_fn != undefined) {
                                props.close_drawer_fn();
                            }
                            else {
                                navigateToReferrer();
                            }
                        }}
                    >
                        <Typography>Skip</Typography>
                    </Button>
                </Box>
            </Container>
        )
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 12,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    <Box sx={{ mt: 3, width: "100%" }}>
                        {(pageState == 'home' || pageState == 'password') && (
                            <PhoneNumberPage
                                userPhoneNumber={userPhoneNumber}
                                setUserPhoneNumber={setUserPhoneNumber}
                                requestOTP={requestOtp}
                                pageState={pageState}
                                setPageState={setPageState}
                            />
                        )}
                        {pageState == 'otp' && (
                            <OTPPage
                                userPhoneNumber={userPhoneNumber}
                                userOtp={userOtp}
                                setUserOtp={setUserOtp}
                                requestOtp={requestOtp}
                                setPageState={setPageState}
                                handleSubmit={handleSubmit}
                            />
                        )}
                        {pageState == 'password' && (
                            <PasswordPage
                                userPassword={userPassword}
                                setUserPassword={setUserPassword}
                                handleSubmit={handleSubmit}
                                setPageState={setPageState}
                            />
                        )}
                    </Box>
                </Box>
            </Container>
        </>
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    access_token: state.auth.access_token,
    refresh_token: state.auth.refresh_token,
    phone_number: state.auth.phone_number,
    first_name: state.auth.first_name,
    last_name: state.auth.last_name,
});
// close_drawer_fn

const mapDispatchToProps = (dispatch) => ({
    loginUser: (formValues) => dispatch(loginUser(formValues)),
    logoutUser: () => dispatch(logoutUser()),
    unifiedOtpVerify: (phone_number, otp) => dispatch(unifiedOtpVerify(phone_number, otp)),
    unifiedRequestOtp: (phone_number) => dispatch(unifiedRequestOtp(phone_number)),
    updateFirstAndLastName: (formValues) => dispatch(updateFirstAndLastName(formValues)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
