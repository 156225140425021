import * as actions from "../api";
import { api_mock } from './mock_api';
import { API_HOST } from "../../Constants";
import Cookies from 'js-cookie';
import { notify } from 'react-notify-toast';
import { ErrorMessage } from "../../Controllers/Notifications";

var ISMOCK = false;

let store;
export const injectStore = _store => {
    store = _store
}

const axios = require('axios');
export const axiosApiInstance = axios.create();

// Request interceptor for API calls. Add access token to API calls header.
axiosApiInstance.interceptors.request.use(
    async config => {
        let access_token = store.getState().auth.access_token;
        if (access_token != undefined) {
            config.headers['Authorization'] = `Bearer ${access_token}`;
        }
        const csrftoken = Cookies.get('csrftoken');
        config.headers['X-CSRFToken'] = csrftoken;
        return config;
    },
    error => {
        Promise.reject(error)
    });


function refreshAccessToken() {
    return async function () {
        let refresh_token = store.getState().auth.refresh_token;
        let api_endpoint = `${API_HOST}/auth/token_refresh/`;
        if (refresh_token == null) {
            return;
        }
        try {
            const { data } = await axios({
                method: 'post',
                url: api_endpoint,
                data: { "refresh": refresh_token },
                headers: {
                    'Accept': "application/json",
                    'Content-Type': 'application/json'
                },
            });
            let access_token = data["access"];
            store.dispatch({ type: 'auth/setRefreshedAccessToken', payload: { access_token: access_token } });
            return access_token;
        } catch (error) {
            var reason = '';
            try {
                reason = error.response.data.code;
            }
            catch {
                reason = 'unknown';
            }
            // if the refresh token is invalid, we logout, since we will need to login again.
            if (reason == "token_not_valid") {
                store.dispatch({ type: 'auth/logoutUserAction', payload: { reload: true } });
            }
            if (axios.isAxiosError(error)) {
                console.log("Axios error", error);
            } else {
                console.log("Unexpected error in refreshAccessToken", error);
            }
        }
    }
}

// Response interceptor for API calls.
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken()();
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
});

async function make_axios_call(method, url, data, headers) {
    if (ISMOCK) {
        console.log("call mock api:", url, method, data);
        const api_response = await api_mock(url, method, data);
        return [api_response, true];
    }
    else {
        try {
            const response = await axiosApiInstance({
                method: method,
                url: `${API_HOST}${url}`,
                data: data,
                headers: headers,
            });
            return [response.data, true];
        } catch (error) {
            console.log("failed error:", error);
            return ['Internal API error', false];
            // if (axios.isAxiosError(error)) {
            //     return [error.response.data, false];
            // } else {
            //     return ['Internal API error', false];
            // }
        }
    }
}

export async function download_images(collection_id) {
    try {
        notify.show('Downloading image...', 'info');
        const response = await axiosApiInstance({
            method: 'get',
            url: `${API_HOST}/creator/download_images_for_insta/`,
            params: {
                id: collection_id,
            },
            responseType: 'blob', // Set the responseType to 'blob'
        });
        notify.hide();
        const blob = new Blob([response.data], { type: 'application/zip' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', collection_id + '.zip');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    } catch (error) {
        notify.hide();
        console.log("failed error:", error);
        ErrorMessage("Download Failed.")
    }
}


const api = ({ dispatch }) => next => async action => {
    // Bypass, if the api middleware should not act on this action type. 
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const { url, method, data_to_server, data, onStart, onSuccess, onError, successApiCallPayload } = action.payload;

    if (onStart) dispatch({ type: onStart, payload: { data: data } });

    next(action);

    var headers = {
        'Accept': "application/json",
        'Content-Type': 'application/json',
    };

    const [response_data, response_status] = await make_axios_call(method, url, data_to_server, headers);

    if (response_status) {
        dispatch(actions.apiCallSuccess(response_data));
    }

    if (response_status && onSuccess) {
        dispatch({ type: onSuccess, payload: { data: data, server_response: response_data } });
        if (successApiCallPayload != undefined) {
            dispatch(
                actions.apiCallBegan(successApiCallPayload)
            );
        }
    }
    if (!response_status && onError) {
        dispatch({ type: onError, payload: { server_response: response_data } });
    }
}

export default api;