import React from "react";

import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import PhoneInput from "react-phone-input-2";
// https://github.com/bl00mber/react-phone-input-2
// has support for preffered countries to keep certain countries at the top of the drop down
// could exclude countries too
// currently onCountries is being used
import "react-phone-input-2/lib/style.css";

const PhoneNumberPage = ({
    userPhoneNumber,
    setUserPhoneNumber,
    pageState,
    setPageState
}) => {
    return (
        <>
            <form action="post">
                <PhoneInput
                    onlyCountries={["in"]}
                    containerStyle={{
                        height: "4em",
                    }}
                    inputStyle={{
                        height: "100%",
                        width: "100%",
                        fontSize: "1.5em",
                        paddingLeft: "3em",
                        borderRadius: "1em",
                    }}
                    buttonStyle={{
                        borderBottomLeftRadius: "1em",
                        borderTopLeftRadius: "1em",
                        height: "4em",
                        width: "4em",
                        padding: "0.5em",
                    }}
                    dropdownStyle={{
                        color: "black",
                        borderRadius: "0.5em",
                    }}
                    value={userPhoneNumber}
                    onChange={(newVal) =>
                        setUserPhoneNumber(newVal)
                    }
                    inputProps={{ autoComplete: "tel" }}
                    country={"in"}
                    disableDropdown={false}
                    enableSearchField={true}
                    disabled={pageState != 'home'}
                />
            </form>
            {/* Choose Password or OTP Buttons */}
            {pageState == 'home' && (
                <ButtonGroup sx={{ mt: 3, mb: 1, width: "100%" }}>
                    <Button
                        fullWidth
                        style={{
                            borderTopLeftRadius: "2em",
                            borderBottomLeftRadius: "2em",
                        }}
                        variant="contained"
                        disabled={userPhoneNumber.length < 10}
                        onClick={() => {
                            setPageState('password')
                        }}
                    >
                        Enter Password
                    </Button>
                    <Button
                        style={{
                            borderTopRightRadius: "2em",
                            borderBottomRightRadius: "2em",
                        }}
                        fullWidth
                        variant="contained"
                        disabled={userPhoneNumber.length < 10}
                        onClick={(e) => {
                            setPageState('otp');
                        }}
                    >
                        Request OTP
                    </Button>
                </ButtonGroup>
            )}
        </>
    );
};

export default PhoneNumberPage;