import React, { useState, useEffect } from "react";
import { Button, Checkbox, Typography } from "@mui/material";
import { connect } from "react-redux";
import { fetchUserCollections } from "../../store/updateInstagramInfo";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Card, CardContent, Link, TextField } from '@mui/material';
import { updateSocialInformation } from '../../store/collections';

const NUM_TO_FETCH = 5;

function JsonVisualizer({ data, updateSocialInformationFn }) {
    const [instagramUrl, setInstagramUrl] = useState(data.social_metadata.instagramUrl);
    const [isChanged, setIsChanged] = useState(false);

    const handleInputChange = (event) => {
        setInstagramUrl(event.target.value);
        setIsChanged(true);
    };

    const handleSubmit = () => {
        const updatedSocialMetadata = {
            ...data.social_metadata,
            instagramUrl: instagramUrl
        };
        updateSocialInformationFn(data.id, updatedSocialMetadata)
        setIsChanged(false);
    };

    const imgStyle = {
        maxHeight: '400px', // Maximum height for the image
        width: 'auto',      // Maintain the aspect ratio
        margin: 'auto',     // Center the image vertically
        display: 'block'    // Needed to make margin auto work
    };

    const cardStyle = {
        display: 'flex',
        marginBottom: '20px'
    };

    const boxStyle = {
        width: '50%',
        marginRight: '16px'
    };

    const boxRightStyle = {
        ...boxStyle,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "rgba(220, 220, 220, 0.5)"
    };

    return (
        <Card elevation={3} style={cardStyle}>
            <Box style={boxStyle}>
                <Carousel showThumbs={true}>
                    {data.board_image_urls.map((imgArr, idx) => (
                        <div key={idx}>
                            <img src={imgArr[0]} alt={`board-${idx}`} style={imgStyle} />
                        </div>
                    ))}
                </Carousel>
            </Box>
            <Box style={boxRightStyle}>
                <CardContent style={{ width: "100%" }}>
                    <Typography variant="h6">Instagram URL:</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <TextField
                            value={instagramUrl}
                            onChange={handleInputChange}
                            fullWidth
                            style={{ flex: 1 }}  // Allows TextField to take up available space
                        />
                        <Button
                            variant="outlined"
                            onClick={() => window.open(instagramUrl, "_blank")} // Opens the link in a new tab
                        >
                            Visit
                        </Button>
                    </div>
                    {isChanged && (
                        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '10px' }}>
                            Submit
                        </Button>
                    )}
                    <div>
                        {data.social_metadata.postingDate}: {data.social_metadata.timeOfDay}
                    </div>
                </CardContent>
            </Box>
        </Card >
    );
}


function InstaCollectionHome(props) {
    useEffect(() => {
        props.fetchUserCollections(0, NUM_TO_FETCH);
    }, []);

    const navigate = useNavigate();

    return (
        <div>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ overflow: 'auto', padding: '2rem', paddingTop: '30px', flexGrow: 1, height: "80vh" }}>
                    {props.collections_list.length !== 0 &&
                        props.collections_list.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "20px",
                                    }}>
                                    <JsonVisualizer data={props.collections_list[index]} updateSocialInformationFn={props.updateSocialInformation} />
                                </div>
                            );
                        })}
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    padding: "10px"
                }}
            >
                <Button
                    variant="outlined"
                    onClick={() => {
                        props.fetchUserCollections(props.collections_list.length, NUM_TO_FETCH);
                    }}>
                    Fetch More
                </Button>
            </Box>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    collections_list: state.collections_instagram_info.collections_list,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserCollections: (start_from, num_to_fetch) => dispatch(fetchUserCollections(start_from, num_to_fetch)),
    updateSocialInformation: (collection_id, social_information) => dispatch(updateSocialInformation(collection_id, social_information)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstaCollectionHome);