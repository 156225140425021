import React, { useState } from "react";

function SearchPage() {
  const [done, setDone] = useState(false);
  return (
    <div className="">
      {/* {!done ? (
        <RecreateLook setDone={setDone} />
      ) : (
        <PhotoPage setDone={setDone} />
      )} */}
    </div>
  );
}

export default SearchPage;
