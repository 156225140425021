import React from 'react';
import { useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { logoutUser } from '../../store/auth';
import { connect } from "react-redux";

function Logout(props) {
    useEffect(() => {
        props.logoutUser();
    }, []);

    return (
        <Navigate to="/" />
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);