import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Menu, MenuItem } from '@mui/material';
import { UploadOutfitBoardPage } from '../OutfitBoards/uploadBoard';
import { connect } from "react-redux";
import { uploadBase64Images, clearUploadedImagesFn } from '../../store/upload';
import {
    AddImageCDNPage,
    AddVideoCDNPage,
    UploadImagePage,
    UploadVideoPage,
    AddProductUrlPage
} from './MediaAndProductUploadForms';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';

const DropdownButton = ({ options, handleSetChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleButtonClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (option) => {
        handleSetChange(option.value);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{}}>
            <Button variant="outlined" onClick={handleButtonClick}>
                Add Item
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {options.map((option, index) => (
                    <MenuItem key={index} onClick={() => handleMenuItemClick(option)}>
                        <Typography>{option.text}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

const OPTIONS = [
    {
        'text': "Add Image CDN",
        'value': 'image_cdn'
    },
    {
        'text': "Add Video CDN ",
        'value': 'video_cdn'
    },
    {
        'text': "Upload Image",
        'value': 'image_upload'
    },
    {
        'text': "Upload Video ",
        'value': 'video_upload'
    },
    {
        'text': "Add Product URL",
        'value': 'product_url'
    },
    {
        'text': "Shortlist URL",
        'value': 'shortlist_url'
    },
    {
        'text': "Add Outfit",
        'value': 'add_outfit'
    }
];

const AddItemComponent = ({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    addImagesToList,
    addVideosToList,
    addProductUrlsToList,
    addOutfitToList,
    addOutfitBoardImageAtIndex,
    updateBoardContentAtIndex,
    editDetails,
    setEditDetails
}) => {
    const [showModal, setShowModal] = useState(false);
    const [itemType, setItemType] = useState('');
    const [formValue, setFormValue] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        setItemType('');
        setFormValue('');
        setShowModal(false);
    };

    const handleItemtypeSelect = (selectedOptionValue) => {
        setItemType(selectedOptionValue);
        setShowModal(true);
    };

    const getItemTitle = (value) => {
        const item = OPTIONS.find((item) => item.value === value);
        return item ? item.text : '';
    };

    useEffect(() => {
        if (editDetails.editIndex >= 0 && editDetails.editType == 'BOARD_IMAGE') {
            setItemType('add_outfit_board_image');
            setShowModal(true);
        }
        if (editDetails.editIndex >= 0 && editDetails.editType == 'BOARD') {
            setItemType('update_outfit');
            setShowModal(true);
        }
    }, [editDetails]);

    return (
        <div>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <Button variant="contained" onClick={() => { handleItemtypeSelect("add_outfit") }}>
                        Add Outfit
                    </Button>
                </Grid>
                <Grid item>
                    <DropdownButton options={OPTIONS} handleSetChange={handleItemtypeSelect} />
                </Grid>
            </Grid>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        marginLeft: '2%',
                        marginTop: '2%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        width: '95%',
                        height: '95%',
                        textAlign: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => setShowModal(false)}
                        sx={{
                            position: 'absolute',
                            top: 1,
                            right: 0
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {getItemTitle(itemType)}
                    </Typography>
                    {itemType === 'add_outfit'
                        && <UploadOutfitBoardPage
                            is_image_uploading={is_image_uploading}
                            uploaded_images={uploaded_images}
                            uploadBase64ImagesFn={uploadBase64ImagesFn}
                            clearUploadedImagesFn={clearUploadedImagesFn}
                            addOutfitToList={(board) => {
                                addOutfitToList(board);
                                setShowModal(false);
                            }}
                        />
                    }
                    {itemType === 'update_outfit'
                        && <UploadOutfitBoardPage
                            is_image_uploading={is_image_uploading}
                            uploaded_images={uploaded_images}
                            uploadBase64ImagesFn={uploadBase64ImagesFn}
                            clearUploadedImagesFn={clearUploadedImagesFn}
                            init_board={editDetails.editInfo}
                            addOutfitToList={(board) => {
                                updateBoardContentAtIndex(editDetails.editIndex, board);
                                setShowModal(false);
                                setEditDetails({ editIndex: -1, editInfo: {}, editType: "" });
                            }}
                        />
                    }
                    {itemType === 'add_outfit_board_image'
                        && <UploadImagePage
                            is_image_uploading={is_image_uploading}
                            uploaded_images={uploaded_images}
                            uploadBase64ImagesFn={uploadBase64ImagesFn}
                            clearUploadedImagesFn={clearUploadedImagesFn}
                            addImagesToList={(image_urls) => {
                                addOutfitBoardImageAtIndex(editDetails.editIndex, image_urls);
                                setShowModal(false);
                                setEditDetails({ editIndex: -1, editInfo: {}, editType: "" });
                            }}
                        ></UploadImagePage>
                    }
                    {itemType === 'image_cdn'
                        && <AddImageCDNPage
                            addImagesToList={(image_urls) => {
                                addImagesToList(image_urls);
                                setShowModal(false);
                            }}
                        ></AddImageCDNPage>
                    }
                    {itemType === 'video_cdn'
                        && <AddVideoCDNPage
                            addVideosToList={(video_urls) => {
                                addVideosToList(video_urls);
                                setShowModal(false);
                            }}
                        ></AddVideoCDNPage>
                    }
                    {itemType === 'image_upload'
                        && <UploadImagePage
                            is_image_uploading={is_image_uploading}
                            uploaded_images={uploaded_images}
                            uploadBase64ImagesFn={uploadBase64ImagesFn}
                            clearUploadedImagesFn={clearUploadedImagesFn}
                            addImagesToList={(image_urls) => {
                                addImagesToList(image_urls);
                                setShowModal(false);
                            }}
                        ></UploadImagePage>
                    }
                    {itemType === 'video_upload'
                        && <UploadVideoPage
                            is_image_uploading={is_image_uploading}
                            uploaded_images={uploaded_images}
                            uploadBase64ImagesFn={uploadBase64ImagesFn}
                            clearUploadedImagesFn={clearUploadedImagesFn}
                            addVideosToList={(video_urls) => {
                                addVideosToList(video_urls);
                                setShowModal(false);
                            }}
                        ></UploadVideoPage>
                    }
                    {itemType === 'product_url'
                        && <AddProductUrlPage
                            addProductUrlsToList={(video_urls) => {
                                addProductUrlsToList(video_urls);
                                setShowModal(false);
                            }}
                        ></AddProductUrlPage>
                    }
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    is_image_uploading: state.upload.is_image_uploading,
    uploaded_images: state.upload.uploaded_base64_images
});

const mapDispatchToProps = (dispatch) => ({
    uploadBase64ImagesFn: (tagname, base64_images_mapping) => dispatch(uploadBase64Images(tagname, base64_images_mapping)),
    clearUploadedImagesFn: () => dispatch(clearUploadedImagesFn())
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddItemComponent);