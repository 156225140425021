import React, { useState } from 'react';
import { uploadBase64Images, clearUploadedImagesFn } from '../../store/upload';
import collections, { createNewCollection, fetchCollectionWithId, updateCollection, publishOutfitBoardCollection, updateSocialInformation, finalizePost, saveWishlinks, unPublishCollection } from '../../store/collections';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import EditCollectionsInfo from './EditCollectionsInfo';
import { useEffect } from 'react';
import DisplayOrEditCollectionInfo from './DisplayOrEditCollectionInfo';
import Button from '@mui/material/Button';

function CreateNewCollectionForm({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    createNewCollection }) {
    const navigate = useNavigate();
    return (
        <>
            <Button style={{
                color: "black",
                backgroundColor: "#aaaaa",
                marginTop: "10px",
                marginBottom: "10px",
            }}
                variant="outlined" onClick={() => {
                    navigate('/collection/')
                }}>
                Back
            </Button>
            <EditCollectionsInfo
                is_image_uploading={is_image_uploading}
                uploaded_images={uploaded_images}
                uploadBase64ImagesFn={uploadBase64ImagesFn}
                clearUploadedImagesFn={clearUploadedImagesFn}
                onSubmitFn={(values) => {
                    createNewCollection(
                        {
                            visible_name: values.visible_name,
                            subtitle: values.subtitle,
                            status: values.status,
                            media: {
                                representative_image: values.representative_image.length > 0 ? values.representative_image[0] : '',
                                video: ""
                            }
                        }
                    )
                }}>
            </EditCollectionsInfo>
        </>

    )
}

const CreateOrEditCollections = ({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    createNewCollection,
    updateCollection,
    publishOutfitBoardCollection,
    fetchCollectionWithId,
    new_collection_id,
    collection,
    updateSocialInformation,
    finalizePost,
    saveWishlinks,
    unPublishCollection
}) => {

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (new_collection_id != undefined) {
            navigate('/collection/edit/' + new_collection_id);
        }
    }, [new_collection_id]);

    useEffect(() => {
        if (id) {
            fetchCollectionWithId(id);
        }
    }, [id]);

    return (
        <>
            {id &&
                <div>
                    {collection && collection.id && collection.id == id &&
                        <DisplayOrEditCollectionInfo
                            state={collection}
                            is_image_uploading={is_image_uploading}
                            uploaded_images={uploaded_images}
                            uploadBase64ImagesFn={uploadBase64ImagesFn}
                            clearUploadedImagesFn={clearUploadedImagesFn}
                            updateCollection={updateCollection}
                            publishOutfitBoardCollection={publishOutfitBoardCollection}
                            submitSocialInfo={(social_info) => { updateSocialInformation(collection.id, social_info) }}
                            finalizePost={() => { finalizePost(collection.id) }}
                            saveWishlinks={saveWishlinks}
                            unPublishCollection={unPublishCollection}
                        />}
                </div>
            }
            {!id &&
                <CreateNewCollectionForm
                    is_image_uploading={is_image_uploading}
                    uploaded_images={uploaded_images}
                    uploadBase64ImagesFn={uploadBase64ImagesFn}
                    clearUploadedImagesFn={clearUploadedImagesFn}
                    createNewCollection={createNewCollection}
                />
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    is_image_uploading: state.upload.is_image_uploading,
    uploaded_images: state.upload.uploaded_base64_images,
    new_collection_id: state.collections.new_collection_id,
    collection: state.collections.collection,
});

const mapDispatchToProps = (dispatch) => ({
    uploadBase64ImagesFn: (tagname, base64_images_mapping) => dispatch(uploadBase64Images(tagname, base64_images_mapping)),
    clearUploadedImagesFn: () => dispatch(clearUploadedImagesFn()),
    createNewCollection: (collection_info) => dispatch(createNewCollection(collection_info)),
    fetchCollectionWithId: (collection_id) => dispatch(fetchCollectionWithId(collection_id)),
    updateCollection: (info) => dispatch(updateCollection(info)),
    publishOutfitBoardCollection: (collection_id) => dispatch(publishOutfitBoardCollection(collection_id)),
    updateSocialInformation: (collection_id, social_information) => dispatch(updateSocialInformation(collection_id, social_information)),
    finalizePost: (collection_id) => dispatch(finalizePost(collection_id)),
    saveWishlinks: (url_to_link_mapping) => dispatch(saveWishlinks(url_to_link_mapping)),
    unPublishCollection: (collection_id) => dispatch(unPublishCollection(collection_id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateOrEditCollections);