import React, { useState } from 'react';
import { List, ListItem, ListItemText, Typography, Link, Button } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { SuccessMessage, ErrorMessage } from "../../Controllers/Notifications";
import { Switch, FormControlLabel, TextField } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const copyToClipboard = (text) => {
    if (!navigator.clipboard) {
        // Fallback for browsers without Clipboard API
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        SuccessMessage("URL copied to clipboard");
        return;
    }

    // Using Clipboard API
    navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
        if (result.state === 'granted' || result.state === 'prompt') {
            navigator.clipboard.writeText(text).then(() => {
                SuccessMessage("URL copied to clipboard");
            }).catch(err => {
                ErrorMessage("Failed to  copy url");
            });
        } else {
            ErrorMessage("Clipboard permission denied");
        }
    });
}

const DisplayItems = ({ items, saveWishlinkFn }) => {
    const [showOnlyEmptyAffiliates, setShowOnlyEmptyAffiliates] = useState(false);
    const [updatedLinksMapping, setUpdatedLinksMapping] = useState({});

    const filteredItems = showOnlyEmptyAffiliates ? items.filter(item => !item.affiliate_link) : items;

    const handleAffiliateChange = (url, newAffiliateLink) => {
        setUpdatedLinksMapping(prevLinks => ({
            ...prevLinks,
            [url]: newAffiliateLink,
        }));
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showOnlyEmptyAffiliates}
                            onChange={() => setShowOnlyEmptyAffiliates(!showOnlyEmptyAffiliates)}
                            color="primary"
                        />
                    }
                    label="Show Only Empty Affiliate"
                />
                {Object.keys(updatedLinksMapping).length > 0 && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            saveWishlinkFn(updatedLinksMapping);
                        }}
                        style={{ backgroundColor: 'lightblue' }}>
                        Save Affiliate Links
                    </Button>
                )}
            </div>
            <List>
                {filteredItems.map((item, idx) => (
                    <ListItem key={idx}>
                        <ListItemText>
                            <Typography variant="body1" display="inline">Board:{item.index + 1} / {item.category_key}:  </Typography>
                            <Link href={item.url} target="_blank" rel="noopener noreferrer" display="inline">
                                {item.url.length > 100 ? `${item.url.substring(0, 100)}...` : item.url}
                            </Link>
                            <Button
                                startIcon={<FileCopyIcon />}
                                size="small"
                                onClick={() => copyToClipboard(item.url)}
                                style={{ marginLeft: '10px' }}
                            >
                                Copy
                            </Button>
                            <TextField
                                label="Affiliate Link"
                                value={updatedLinksMapping[item.url] || item.affiliate_link || ''}
                                onChange={e => handleAffiliateChange(item.url, e.target.value)}
                                fullWidth
                            />
                            <Button
                                size="small"
                                onClick={() => window.open(updatedLinksMapping[item.url] || item.affiliate_link, '_blank')}
                                style={{ marginLeft: '10px' }}
                            >
                                <OpenInNewIcon />
                            </Button>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

export default DisplayItems;
