import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddItemComponent from "./AddItemToCollection";
import Chip from '@mui/material/Chip';
import PlaceholderImageIcon from '@mui/icons-material/Image'; // Placeholder image icon from Material-UI
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ImageGrid from "../../components/Common/ImageGrid";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function RenderImageItem({ item, maxHeight = '300px' }) {
    return (
        <img
            src={item.item_url}
            alt="Item"
            style={{ maxWidth: '100%', maxHeight: maxHeight, objectFit: 'contain' }}
        />
    );
}

function RenderVideoItem({ item, maxHeight = '300px' }) {
    return (
        <video
            controls
            src={item.item_url}
            alt="Item"
            style={{ maxWidth: '100%', maxHeight: maxHeight, objectFit: 'contain' }}
        />
    );
}

const BeautifiedProductUrl = (productUrl) => {
    const maxLength = 100;
    let shortenedUrl = productUrl.slice(0, maxLength);
    if (productUrl.length > maxLength) {
        shortenedUrl += ' ...';
    }
    return shortenedUrl;
};


function RenderProductItem({ item, maxHeight = '300px' }) {
    return (
        <a href={item.item_url} target="_blank" rel="noopener noreferrer">
            {BeautifiedProductUrl(item.item_url)}
        </a>
    )
}

const DisplayKV = ({ kv }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Key</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(kv).map(([key, value]) => (
                        <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const BoardDetails = ({ board, board_metadata, features, onImageClickFn }) => {
    const {
        board_image_url,
        context_topic,
        main_hashtag,
        additional_hashtags,
        other_hashtags,
        canva_link,
        index,
        ...others
    } = board;

    const handleChipClick = (url) => {
        window.open(url, '_blank');
    };

    const categories = ['tops', 'bottoms', 'outerwear', 'dresses', 'bag', 'footwear', 'accessories'];

    const [modalState, setModalState] = useState('NA');
    const [gridImages, setGridImages] = useState([]);

    const handleOpen = (state) => {
        setModalState(state);
    };

    const handleClose = () => {
        setModalState('NA');
    };

    useEffect(() => {
        if (features && features['product_info']) {
            var grid_image_list = [];
            for (let key in features['product_info']) {
                grid_image_list.push({
                    'image_path': features['product_info'][key]['image_url'],
                    'text': features['product_info'][key]['broad_category'] + " " + features['product_info'][key]['brand']
                })
            }
            setGridImages(grid_image_list);
        }
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Chip label={index} variant="outlined" />
                <Box flex="1" maxWidth="200px" pr={2}>
                    {board_image_url.length > 0 ? (
                        <img src={board_image_url[0]} alt="Board" style={{ maxWidth: '100%', height: 'auto' }} />
                    ) : (
                        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                            <PlaceholderImageIcon onClick={onImageClickFn} style={{ fontSize: 150 }} />
                        </Box>
                    )}
                </Box>
                <Box flex="1" maxWidth="calc(100% - 200px)">
                    <Typography variant="subtitle1" gutterBottom>
                        {context_topic}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {[main_hashtag, ...additional_hashtags, ...other_hashtags].join(', ')}
                    </Typography>
                    {categories.map((category) => {
                        const items = others[category];
                        if (items && items.length > 0) {
                            return items.map((item, index) => (
                                <Chip
                                    key={category + String(index)} // Assuming each item has a unique identifier
                                    label={category.charAt(0).toUpperCase() + category.slice(1)}
                                    color="primary"
                                    style={{ marginRight: '0.5rem', cursor: 'pointer' }}
                                    onClick={() => handleChipClick(item)}
                                />
                            ));
                        }
                        return null;
                    })}
                    <Modal open={modalState != 'NA'} onClose={handleClose}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                            <IconButton sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                            {modalState == "IMAGE_GRID" && <ImageGrid images={gridImages}></ImageGrid>}
                            {modalState == "HEALTH" && <DisplayKV kv={features && features['health'] ? features['health'] : {}}></DisplayKV>}
                            {modalState == "SPEC" && <DisplayKV kv={features && features['spec'] ? features['spec'] : {}}></DisplayKV>}
                        </Box>
                    </Modal>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="flex-end">
                {board_metadata && board_metadata.error && (
                    <p style={{ color: 'red' }}>{board_metadata.error}</p>
                )}
                {board_metadata && board_metadata.success && (
                    <p style={{ color: 'green' }}>{board_metadata.success}</p>
                )}
                <Chip
                    label="View Extracted Products"
                    style={{
                        marginLeft: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#FFCDD2', // Replace with your desired pastel red color
                    }}
                    onClick={() => handleOpen("IMAGE_GRID")}
                />
                <Chip
                    label="View Health"
                    style={{
                        marginLeft: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#FFCDD2', // Replace with your desired pastel red color
                    }}
                    onClick={() => handleOpen("HEALTH")}
                />
                <Chip
                    label="View Spec"
                    style={{
                        marginLeft: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#FFCDD2', // Replace with your desired pastel red color
                    }}
                    onClick={() => handleOpen("SPEC")}
                />
            </Box>
        </Box>
    );
};

const getValueFromDictionary = (dictionary, key) => {
    if (dictionary.hasOwnProperty(key)) {
        return dictionary[key];
    } else {
        return undefined;
    }
};


const CollectionItemList = ({ updatedItems, setUpdatedItems, setHasUnsavedChanges, disableEdits, outfitFeatures, creationStatus, maxHeight = "200px" }) => {
    const [editDetails, setEditDetails] = useState({ editIndex: -1, editInfo: {}, editType: "" });

    const handleScoreChange = (itemIndex, newScore) => {
        setUpdatedItems((prevItems) =>
            prevItems.map((item, index) =>
                index === itemIndex ? { ...item, scores: { ...item.scores, display_score: newScore } } : item
            )
        );
    };

    const addImagesToList = (imageUrls) => {
        setUpdatedItems((prevItems) => {
            // Find the lowest display score in the current list
            const lowestScore = prevItems.reduce(
                (minScore, item) => (item.scores.display_score < minScore ? item.scores.display_score : minScore),
                1
            );

            // Generate new item objects for each image URL and add them to the list
            const newItems = imageUrls.map((imageUrl) => ({
                item_url: imageUrl,
                item_id: '',
                item_type: 'IMAGE',
                item_status: 'ACTIVE',
                scores: {
                    display_score: lowestScore,
                },
            }));

            // Append the new items to the existing list and return the updated list
            return [...prevItems, ...newItems];
        });
        setHasUnsavedChanges(true);
    };

    const addVideosToList = (videoUrls) => {
        setUpdatedItems((prevItems) => {
            // Find the lowest display score in the current list
            const lowestScore = prevItems.reduce(
                (minScore, item) => (item.scores.display_score < minScore ? item.scores.display_score : minScore),
                1
            );

            // Generate new item objects for each video URL and add them to the list
            const newItems = videoUrls.map((videoUrl) => ({
                item_url: videoUrl,
                item_id: '',
                item_type: 'VIDEO',
                item_status: 'ACTIVE',
                scores: {
                    display_score: lowestScore,
                },
            }));

            // Append the new items to the existing list and return the updated list
            return [...prevItems, ...newItems];
        });
        setHasUnsavedChanges(true);
    };

    const addProductUrlsToList = (productUrls) => {
        setUpdatedItems((prevItems) => {
            // Find the lowest display score in the current list
            const lowestScore = prevItems.reduce(
                (minScore, item) => (item.scores.display_score < minScore ? item.scores.display_score : minScore),
                1
            );

            // Generate new item objects for each product URL and add them to the list
            const newItems = productUrls.map((productUrl) => ({
                item_url: productUrl,
                item_id: '',
                item_type: 'PRODUCT',
                item_status: 'ACTIVE',
                scores: {
                    display_score: lowestScore,
                },
            }));

            // Append the new items to the existing list and return the updated list
            return [...prevItems, ...newItems];
        });
        setHasUnsavedChanges(true);
    };

    const addOutfitToList = (board) => {
        setUpdatedItems((prevItems) => {
            // Find the lowest display score in the current list
            const lowestScore = prevItems.reduce(
                (minScore, item) => (item.scores.display_score < minScore ? item.scores.display_score : minScore),
                1
            );

            // Generate new item objects for each product URL and add them to the list
            const newItems = [{
                item_url: '',
                item_id: '',
                item_type: 'OUTFIT',
                item_status: 'ACTIVE',
                item_content: board,
                scores: {
                    display_score: lowestScore,
                },
            }]

            // Append the new items to the existing list and return the updated list
            return [...prevItems, ...newItems];
        });
        setHasUnsavedChanges(true);
    };

    const updateBoardImageUrlForOutfitAtIndex = (index, image_url_list) => {
        setUpdatedItems((prevItems) => {
            const updatedItems = JSON.parse(JSON.stringify(prevItems));
            updatedItems[index].item_content.board_image_url = image_url_list;
            return updatedItems;
        });
        setHasUnsavedChanges(true);
    };

    const updateBoardContentAtIndex = (index, updatedContent) => {
        setUpdatedItems((prevItems) => {
            const updatedItems = JSON.parse(JSON.stringify(prevItems));
            updatedItems[index].item_content = updatedContent;
            return updatedItems;
        });
        setHasUnsavedChanges(true);
    };

    const handleStatusChange = (itemIndex) => {
        setUpdatedItems((prevItems) =>
            prevItems.map((item, index) =>
                index === itemIndex ? { ...item, item_status: item.item_status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE' } : item
            )
        );
        setHasUnsavedChanges(true);
    };

    const handleDeleteItem = (itemIndex) => {
        setUpdatedItems((prevItems) => prevItems.filter((_, index) => index !== itemIndex));
        setHasUnsavedChanges(true);
    };

    const handleResort = () => {
        setUpdatedItems((prevItems) =>
            [...prevItems].sort((a, b) => b.scores.display_score - a.scores.display_score)
        );
        setHasUnsavedChanges(true);
    };

    return (
        <Box style={{ flexGrow: 1 }}>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between" style={{ paddingRight: '2rem', paddingLeft: '2rem' }}
            >
                <Grid item>
                    {!disableEdits && <AddItemComponent
                        addImagesToList={addImagesToList}
                        addVideosToList={addVideosToList}
                        addProductUrlsToList={addProductUrlsToList}
                        addOutfitToList={addOutfitToList}
                        addOutfitBoardImageAtIndex={updateBoardImageUrlForOutfitAtIndex}
                        updateBoardContentAtIndex={updateBoardContentAtIndex}
                        editDetails={editDetails}
                        setEditDetails={setEditDetails}
                    />}
                </Grid>
                <Grid item>
                    <Button variant="outlined" onClick={handleResort} disabled={creationStatus == "POSTED"}>
                        Resort
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ overflow: 'auto', padding: '2rem', paddingTop: '10px', flexGrow: 1, height: "50vh" }}>

                    {updatedItems.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '1rem',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                marginBottom: '1rem',
                            }}
                        >
                            <Box sx={{ width: '80%' }}>
                                <Typography variant="h6">{item.item_type}</Typography>
                                {item.item_type === 'IMAGE' && (
                                    <RenderImageItem item={item} maxHeight={maxHeight} />
                                )}
                                {item.item_type === 'VIDEO' && (
                                    <RenderVideoItem item={item} maxHeight={maxHeight} />
                                )}
                                {item.item_type === 'PRODUCT' && (
                                    <RenderProductItem item={item} maxHeight={maxHeight} />
                                )}
                                {item.item_type === 'OUTFIT' && (
                                    <BoardDetails
                                        board={item.item_content}
                                        board_metadata={item.item_metadata}
                                        features={getValueFromDictionary(outfitFeatures, item.item_id)}
                                        onImageClickFn={() => { setEditDetails({ editIndex: index, editInfo: {}, editType: "BOARD_IMAGE" }) }}
                                        maxHeight={maxHeight}
                                    />
                                )}
                            </Box>
                            <Box sx={{ width: '20%', textAlign: 'right' }}>
                                <Typography variant="body1">Display Score:</Typography>
                                <TextField
                                    type="number"
                                    value={item.scores.display_score}
                                    onChange={(e) => handleScoreChange(index, e.target.value)}
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    disabled={creationStatus == "POSTED"}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.item_status === 'ACTIVE'}
                                            onChange={() => handleStatusChange(index)}
                                        />
                                    }
                                    disabled={creationStatus == "POSTED"}
                                    label="Active"
                                />
                                {/* {item.item_id == "" &&
                                    <IconButton onClick={() => handleDeleteItem(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                } */}
                                <IconButton onClick={() => handleDeleteItem(index)} disabled={disableEdits}>
                                    <DeleteIcon />
                                </IconButton>
                                {item.item_type === 'OUTFIT' &&
                                    <IconButton disabled={disableEdits} onClick={() => setEditDetails({ editIndex: index, editInfo: item.item_content, editType: "BOARD" })}>
                                        <EditIcon />
                                    </IconButton>
                                }
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default CollectionItemList;