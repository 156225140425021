import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { SuccessMessage, ErrorMessage, WarningMessage } from "./../Controllers/Notifications";

const slice = createSlice({
    name: "collections",
    initialState: {
        collections_list: [],
        collection: {},
        new_collection_id: undefined
    },
    reducers: {
        fetchCollectionStarted: (state, action) => { },
        fetchCollectionSuccess: (state, action) => {
            state.collection = action.payload.server_response.collection;
        },
        fetchCollectionFailed: (state, action) => { },
        createCollectionStarted: (state, action) => {
            state.new_collection_id = undefined;
        },
        createCollectionSuccess: (state, action) => {
            state.new_collection_id = action.payload.server_response.collection_id;
            SuccessMessage("Created a new collection.");
        },
        createCollectionFailed: (state, action) => {
            console.log("Create collection failed");
            ErrorMessage("Error creating the collection");
        },
        updateCollectionStarted: (state, action) => { },
        updateCollectionSuccess: (state, action) => {
            window.location.reload();
            SuccessMessage("Collection saved to server.");
        },
        updateCollectionFailed: (state, action) => {
            ErrorMessage("Failed to update the collection");
        },
        publishOutfitCollectionStarted: (state, action) => { },
        publishOutfitCollectionSuccess: (state, action) => {
            SuccessMessage(action.payload.server_response.response);
            setTimeout(function () {
                // Reload the page
                window.location.reload();
            }, 3000);
        },
        publishOutfitCollectionFailed: (state, action) => {
            console.log(action.payload.server_response);
            ErrorMessage("Cannot publish outfits collection.");
        },
        updateSocialInformationStarted: (state, action) => { },
        updateSocialInformationSuccess: (state, action) => {
            SuccessMessage("Saved social information");
            setTimeout(function () {
                // Reload the page
                window.location.reload();
            }, 3000);
        },
        updateSocialInformationFailed: (state, action) => {
            console.log(action.payload.server_response);
            ErrorMessage("Cannot update social information.");
        },
        finalizePostStarted: (state, action) => { },
        finalizePostSuccess: (state, action) => {
            SuccessMessage("Post finalized");
            setTimeout(function () {
                // Reload the page
                window.location.reload();
            }, 3000);
        },
        finalizePostFailed: (state, action) => {
            console.log(action.payload.server_response);
            ErrorMessage("Cannot finalize post.");
        },
        fetchUserCollectionsStarted: (state, action) => { },
        fetchUserCollectionsSuccess: (state, action) => {
            var items = state.collections_list;
            if (action.payload.data.start_from >= items.length) {
                if (action.payload.server_response.collections_list.length == 0) {
                    WarningMessage("No more collections found");
                }
                else {
                    items.push(...action.payload.server_response.collections_list);
                    state.collections_list = items;
                }
            }
        },
        fetchUserCollectionsFailed: (state, action) => {
            ErrorMessage("Error fetching the collections");
        },
        wishlinkSaveStarted: (state, action) => { },
        wishlinkSaveSuccess: (state, action) => {
            SuccessMessage(action.payload.server_response.status);
        },
        wishlinkSaveFailed: (state, action) => {
            ErrorMessage("Error saving affiliate links");
        },
        unPublishCollectionStarted: (state, action) => { },
        unPublishCollectionSuccess: (state, action) => {
            SuccessMessage(action.payload.server_response.status);
            setTimeout(function () {
                // Reload the page
                window.location.reload();
            }, 3000);
        },
        unPublishCollectionFailed: (state, action) => {
            ErrorMessage("Error unpublishing collections");
        }
    },
});


export const {
    fetchCollectionStarted,
    fetchCollectionSuccess,
    fetchCollectionFailed,
    createCollectionStarted,
    createCollectionSuccess,
    createCollectionFailed,
    updateCollectionStarted,
    updateCollectionSuccess,
    updateCollectionFailed,
    fetchUserCollectionsStarted,
    fetchUserCollectionsSuccess,
    fetchUserCollectionsFailed,
    publishOutfitCollectionStarted,
    publishOutfitCollectionSuccess,
    publishOutfitCollectionFailed,
    updateSocialInformationStarted,
    updateSocialInformationSuccess,
    updateSocialInformationFailed,
    finalizePostStarted,
    finalizePostSuccess,
    finalizePostFailed,
    wishlinkSaveStarted,
    wishlinkSaveSuccess,
    wishlinkSaveFailed,
    unPublishCollectionStarted,
    unPublishCollectionSuccess,
    unPublishCollectionFailed

} = slice.actions;

export default slice.reducer;

export const fetchUserCollections =
    (start_from, num_to_fetch) => (dispatch, getState) => {
        return dispatch(
            apiCallBegan({
                url: "/creator/fetch_user_collections/",
                data_to_server: { start_from: start_from, num_to_fetch: num_to_fetch },
                data: { start_from: start_from, num_to_fetch: num_to_fetch },
                method: "post",
                onStart: fetchUserCollectionsStarted.type,
                onSuccess: fetchUserCollectionsSuccess.type,
                onError: fetchUserCollectionsFailed.type,
            })
        );
    };

export const fetchCollectionWithId = (id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/fetch_collection_with_id/",
            method: "post",
            data_to_server: { id: id },
            data: { id: id },
            onStart: fetchCollectionStarted.type,
            onSuccess: fetchCollectionSuccess.type,
            onError: fetchCollectionFailed.type,
        })
    );
};

export const createNewCollection = (collection_info) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/create_new_collection/",
            method: "post",
            data_to_server: { info: collection_info },
            data: {},
            onStart: createCollectionStarted.type,
            onSuccess: createCollectionSuccess.type,
            onError: createCollectionFailed.type,
        })
    );
};

export const updateCollection = (collection_info) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/update_collection/",
            method: "post",
            data_to_server: { info: collection_info },
            data: {},
            onStart: updateCollectionStarted.type,
            onSuccess: updateCollectionSuccess.type,
            onError: updateCollectionFailed.type,
        })
    );
};

export const publishOutfitBoardCollection = (collection_id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/publish_outfit_board_collection/",
            method: "post",
            data_to_server: { id: collection_id },
            data: { id: collection_id },
            onStart: publishOutfitCollectionStarted.type,
            onSuccess: publishOutfitCollectionSuccess.type,
            onError: publishOutfitCollectionFailed.type,
        })
    );
};

export const updateSocialInformation = (collection_id, social_information) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/update_social_info/",
            method: "post",
            data_to_server: { id: collection_id, social_information: social_information },
            data: { id: collection_id, social_information: social_information },
            onStart: updateSocialInformationStarted.type,
            onSuccess: updateSocialInformationSuccess.type,
            onError: updateSocialInformationFailed.type,
        })
    );
};

export const finalizePost = (collection_id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/finalize_post/",
            method: "post",
            data_to_server: { id: collection_id },
            data: { id: collection_id },
            onStart: finalizePostStarted.type,
            onSuccess: finalizePostSuccess.type,
            onError: finalizePostFailed.type,
        })
    );
};

export const saveWishlinks = (url_to_wishlink_mapping) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/update_wishlinks/",
            method: "post",
            data_to_server: { url_to_wishlink_mapping: url_to_wishlink_mapping },
            data: { url_to_wishlink_mapping: url_to_wishlink_mapping },
            onStart: wishlinkSaveStarted.type,
            onSuccess: wishlinkSaveSuccess.type,
            onError: wishlinkSaveFailed.type,
        })
    );
};

export const unPublishCollection = (collection_id) => (dispatch, getState) => {
    return dispatch(
        apiCallBegan({
            url: "/creator/unpublish_collection/",
            method: "post",
            data_to_server: { id: collection_id },
            data: { id: collection_id },
            onStart: unPublishCollectionStarted.type,
            onSuccess: unPublishCollectionSuccess.type,
            onError: unPublishCollectionFailed.type,
        })
    );
};

