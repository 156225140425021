export const ADDITIONAL_TAGS = [
    '#90s_persona',
    '#airport_looks',
    '#androgynous_persona',
    '#apple_body_type',
    '#athleisure_persona',
    '#baddie_persona',
    '#bali_outfits',
    '#beach',
    '#birthday_outfits',
    '#bohemian_girl_persona',
    '#brunch',
    '#capsule_wardrobe',
    '#casual_evening',
    '#coffee_date',
    '#college',
    '#college_girl_persona',
    '#concert_night',
    '#dark_academia_persona',
    '#date_night',
    '#diamond_body_type',
    '#dinner',
    '#glamgirl_persona',
    '#goa_trip',
    '#hippie_persona',
    '#hourglass_bodytype',
    '#inverted_triangle_body_type',
    '#it_girl_persona',
    '#kawaii_persona',
    '#korean_inspired',
    '#minimal_persona',
    '#misc',
    '#modest_college_girl',
    '#movie_date',
    '#night_out_outfits',
    '#office',
    '#party',
    '#pear_body_type',
    '#picnic',
    '#preppy_girl_persona',
    '#princess_vibe',
    '#recreate_influencer_celeb_look',
    '#recreate_pinterest_look',
    '#rectangle_body_type',
    '#regular_use_clothing',
    '#soft_girl_persona',
    '#soft_grunge_persona',
    '#sporty_girl',
    '#streetwear_persona',
    '#tomboy',
    '#valentines_day',
    '#vintage_persona',
    '#wedding_season',
    '#womens',
    '#y2k_persona',
]