export const NavBarData = [
  // {
  //   title: "Create Outfit Board",
  //   link: "/createOutfitBoard",
  // },
];

// {
//   title: "Upload",
//   link: "/",
// },
// {
//   title: "Recreate",
//   link: "/recreate",
// },
// {
//   title: "Outfit",
//   link: "/outfit",
// },