import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const uploadSlice = createSlice({
    name: "upload",
    initialState: {
        uploaded_base64_images: { 'tag': '', 'urls': [] },
        is_image_uploading: false
    },
    reducers: {
        uploadBase64ImagesStarted: (state, action) => {
            state.is_image_uploading = true;
        },
        uploadBase64ImagesSuccess: (state, action) => {
            state.is_image_uploading = false;
            state.uploaded_base64_images = { 'tag': action.payload.data.tagname, 'urls': action.payload.server_response.urls };
        },
        uploadBase64ImagesFailed: (state, action) => {
            state.is_image_uploading = false;
        },
        clearUploadedImages: (state, action) => {
            state.uploaded_base64_images = { 'tag': '', 'urls': [] };
        },
    },
});

export const {
    uploadBase64ImagesStarted,
    uploadBase64ImagesSuccess,
    uploadBase64ImagesFailed,
    clearUploadedImages
} = uploadSlice.actions;

export default uploadSlice.reducer;

export const uploadBase64Images =
    (tagname, base64_images_mapping) => (dispatch, getState) => {
        if (base64_images_mapping.length == 0) {
            return;
        }
        return dispatch(
            apiCallBegan({
                url: "/lookbook/upload_base64_images/",
                method: "post",
                data_to_server: { image_list: base64_images_mapping },
                data: { tagname: tagname },
                onStart: uploadBase64ImagesStarted.type,
                onSuccess: uploadBase64ImagesSuccess.type,
                onFailed: uploadBase64ImagesFailed.type,
            })
        );
    };

export const clearUploadedImagesFn = (selected_url) => (dispatch, getState) => {
    return dispatch({ type: clearUploadedImages.type, payload: {} });
};