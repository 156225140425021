import React, { useState } from 'react';
import { Button, Grid, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DisplayItems from './WishlinkCreation';
import { useNavigate } from 'react-router-dom';

const WishlinkCreationModal = ({ collection_id, wishlink_mapping, saveWishlinkFn }) => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const buttonStyle = {
        marginRight: '8px',
        backgroundColor: '#FFC3B7', // Pastel red color
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
        transition: 'background-color 0.3s',
    };

    return (
        <div>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Button style={buttonStyle} onClick={() => { setOpenModal(true); }}>
                        Update Wishlink
                    </Button>
                    <Button style={buttonStyle} onClick={() => { navigate('/collection/bulk_affiliate/' + collection_id) }}>
                        Bulk Wishlink
                    </Button>
                </Grid>
            </Grid>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh', // setting a max height of 80% of the viewport height
                    overflow: 'auto' // enabling scrollbar when the content overflows
                }}>
                    <IconButton sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                    <DisplayItems items={wishlink_mapping} saveWishlinkFn={saveWishlinkFn} />
                </Box>
            </Modal>
        </div >
    );
};

export default WishlinkCreationModal;