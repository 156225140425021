import React, { useState } from 'react';
import SchematicForm from "../../components/SchematicForms";
import { useCustomForm } from '../../components/SchematicForms/CustomForm';
import { GetDefaultValuesFromSchema } from '../../components/SchematicForms';

const collectionCreationFormFieldsSchema = [
    {
        "key": "visible_name",
        "type": "INPUT_STRING",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    },
    {
        "key": "subtitle",
        "type": "INPUT_STRING",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    },
    {
        "key": "status",
        "type": "MULTICLASS",
        "meta": { "values": ["ACTIVE", "INACTIVE"] },
        "disabled": false,
        "step": 1,
        "index": 1
    },
    {
        "key": "access_type",
        "type": "MULTICLASS",
        "meta": { "values": ["ADMIN", "ALL"] },
        "disabled": false,
        "step": 1,
        "index": 1
    },
    {
        "key": "representative_image",
        "type": "IMAGE_UPLOADER",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 1
    }
];

export default function EditCollectionsInfo({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    onSubmitFn,
    initCollectionInfo = undefined
}) {
    const [initialValues, setInitialValues] = useState(
        initCollectionInfo == undefined ? GetDefaultValuesFromSchema(collectionCreationFormFieldsSchema) : initCollectionInfo
    );

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            <SchematicForm
                formFieldsSchema={collectionCreationFormFieldsSchema}
                values={values}
                handleChange={handleChange}
                addRepeatedFieldValue={addRepeatedFieldValue}
                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                is_image_uploading={is_image_uploading}
                uploaded_images={uploaded_images}
                uploadBase64ImagesFn={uploadBase64ImagesFn}
                clearUploadedImagesFn={clearUploadedImagesFn}
                stepToLabel={{}}
                onSubmit={(formValues) => { onSubmitFn(formValues) }}
            />
        </div>
    );
}
