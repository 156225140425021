import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import {
  finalizedFile,
  selectedFile,
  setFinalFile,
  setSelectedFile,
  updateFile,
} from "../../store/pictureSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { setImageToRecreate } from "../../store/recreateSilce";
import { Grid, Button } from '@mui/material';


function UploadSection({ closeModalFn, addUploadedFiles }) {
  const filePickerRef = useRef();

  const dispatch = useDispatch();
  const selectFiles = useSelector(selectedFile);
  const finalFiles = useSelector(finalizedFile)
  const addImage = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const reader = new FileReader();
      if (e.target.files[i]) {
        reader.readAsDataURL(e.target.files[i]);
      }
      reader.onload = (readerEvent) => {
        dispatch(
          setSelectedFile({
            id: uuid(),
            url: readerEvent.target.result,
          })
        );
      };
    }
  };

  const handleDelete = (item) => {
    dispatch(updateFile(selectFiles.filter((items) => items.id !== item.id)));
  };

  const handleSubmit = (e) => {
    closeModalFn();
    if (addUploadedFiles != undefined) {
      addUploadedFiles(selectFiles);
    }
    else {
      if (window.location.pathname == "/") {
        dispatch(setFinalFile([...finalFiles, ...selectFiles]));
      } else if (window.location.pathname == "/recreate") {
        dispatch(setImageToRecreate(...selectFiles))
      }
    }
    dispatch(updateFile([]));
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Grid style={{ height: '10%', width: "100%", textAlign: "center", justifyContent: "center", p: 1 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#000000', // Specify your custom color
            width: '200px', // Specify the desired width
            height: '50px', // Specify the desired height
            color: '#ffffff', // Specify the text color
            '&:hover': {
              backgroundColor: '#333333', // Specify the hover color
            },
          }}
          onClick={() => {
            filePickerRef.current.click();
          }}
        >
          Upload
        </Button>
        <input
          type="file"
          hidden
          multiple
          ref={filePickerRef}
          onChange={addImage}
        />
      </Grid>
      <Grid style={{ height: '80%', width: "100%", overflow: "scroll" }}>
        <Grid container spacing={4}>
          {selectFiles?.map((item, index) => (
            <Grid item key={index} xs={4}>
              <div style={{ overflow: 'hidden' }}>
                <CancelIcon
                  onClick={() => {
                    handleDelete(item);
                  }}
                />
                <img src={item.url} style={{ maxHeight: '250px', height: 'auto' }} />
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid style={{ height: '10%', width: "100%", textAlign: "center", justifyContent: "center", p: 1 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{
            backgroundColor: '#000000', // Specify your custom color
            width: '200px', // Specify the desired width
            height: '50px', // Specify the desired height
            color: '#ffffff', // Specify the text color
            '&:hover': {
              backgroundColor: '#333333', // Specify the hover color
            },
          }}
        >
          Done
        </Button>
      </Grid>
    </div>
  );
}

export default UploadSection;
