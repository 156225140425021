import React, { useEffect, useState } from "react";
import { Box, Tab, Typography } from "@mui/material";
import { connect } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ProductImageList from "./ProductImageList";
import { fetchSearchResults } from "../../store/searchSlice";
import ImageGrid from "../Common/ImageGrid";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function OutfitBoardFeedback({ value, handleFeedbackUpdate }) {
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleFeedbackUpdate}
            >
                <FormControlLabel value="good" control={<Radio />} label="Good" />
                <FormControlLabel value="bad" control={<Radio />} label="Bad" />
                <FormControlLabel value="back" control={<Radio />} label="Back" />
            </RadioGroup>
        </FormControl>
    );
}

function InstructionsAccordion() {
    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>What is a good outfit board ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    A good outfit board is one which
                    <List
                        sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                            },
                        }}>
                        <ListItem>
                            Contains basic outfit items which covers the body, commonly include either tops + bottoms or a dress.
                        </ListItem>
                        <ListItem>
                            Have atleast 3 items in the board.
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1b-content"
                    id="panel1b-header"
                >
                    <Typography>How to select the outfit ?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List
                        sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            '& .MuiListItem-root': {
                                display: 'list-item',
                            },
                        }}>
                        <ListItem>
                            If it is possible to find atleast 3 good items for the outfit, select those items, give feedback as "good", and submit the board.
                        </ListItem>
                        <ListItem>
                            If it is not possible, only select the best products you can find the product name, click on "no" and submit.
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}


function SelectProductsForOutfitBoard({
    toggleToMainFn,
    outfitBoardQuery,
    fetchSearchResultsFn,
    scored_search_response,
}) {
    const CategoriesData = [
        "tops",
        "bottoms",
        "outerwear",
        "dresses",
        "bag",
        "footwear",
        "accessories",
    ];
    const [gridImages, setGridImages] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(CategoriesData.filter(
        (category) => outfitBoardQuery.queries[category].length > 0
    )[0]);

    const [selectedProduct, setSelectedProduct] = useState({});

    function updateSelectedProductForCategory(product, category) {
        setSelectedProduct({ ...selectedProduct, [category]: { product: product } });
        if (product != null) {
            var applicable_categories = CategoriesData.filter(
                (category) => outfitBoardQuery.queries[category].length > 0
            );
            let index = applicable_categories.indexOf(category);
            if (index < (applicable_categories.length - 1)) {
                setSelectedCategory(applicable_categories[index + 1]);
            } else if (index == (applicable_categories.length - 1)) {
                setSelectedCategory(applicable_categories[0]);
            }
        }
    }
    const handleChange = (event, newCategory) => {
        setSelectedCategory(newCategory);
    };

    const [outfitFeedback, setOutfitFeedback] = useState("na");
    const handleOutfitBoardFeedbackUpdate = (event) => {
        setOutfitFeedback(event.target.value);
    };

    useEffect(() => {
        var applicable_categories = CategoriesData.filter(
            (category) => outfitBoardQuery.queries[category].length > 0
        );
        var grid_image_list = [];
        for (var index = 0; index < applicable_categories.length; index++) {
            if (applicable_categories[index] in selectedProduct && selectedProduct[applicable_categories[index]] && selectedProduct[applicable_categories[index]].product) {
                var selected_product = selectedProduct[applicable_categories[index]].product;
                var image = selected_product.media.base_image !== "" ? selected_product.media.base_image : selected_product.media.additional_images[0];
                if (image) {
                    grid_image_list.push({ image_path: image, text: applicable_categories[index] });
                }
                else {
                    grid_image_list.push({ image_path: "https://upload.wikimedia.org/wikipedia/commons/5/59/Empty.png?20091205084734", text: applicable_categories[index] });
                }
            }
            else {
                grid_image_list.push({ image_path: "https://upload.wikimedia.org/wikipedia/commons/5/59/Empty.png?20091205084734", text: applicable_categories[index] });
            }
        }
        setGridImages(grid_image_list);
    }, [selectedProduct]);

    useEffect(() => {
        fetchSearchResultsFn(selectedCategory, outfitBoardQuery.queries[selectedCategory][0], "");
    }, [selectedCategory]);

    return (
        <div>
            <Grid
                container
                spacing={1}
                style={{
                    width: "100%",
                    marginBottom: "20px"
                }}
            >
                <InstructionsAccordion />
            </Grid>
            <Grid
                container
                spacing={1}
                style={{
                    width: "100%",
                }}
            >
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        onClick={() => toggleToMainFn()}
                        disabled={!(outfitFeedback == "back")}
                    >
                        Go Back To Queries
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Typography> Can we create a good outfit ?</Typography>
                    <OutfitBoardFeedback
                        value={outfitFeedback}
                        handleFeedbackUpdate={handleOutfitBoardFeedbackUpdate}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            var pickedCategories = Object.keys(selectedProduct);
                            var is_invalid = false;
                            if (pickedCategories.length < 3) {
                                is_invalid = true;
                            }
                            var num_valid_products = 0;
                            for (var index = 0; index < pickedCategories.length; index++) {
                                if (!(selectedProduct[pickedCategories[index]].product == null)) {
                                    num_valid_products += 1;
                                }
                            }
                            var is_board_valid = num_valid_products > 3;
                            if (outfitFeedback == "good" && !is_board_valid) {
                                alert("If feedback is good, please select atleast 3 items.");
                            }
                            else {
                                console.log("Submit");
                                console.log(selectedProduct, outfitFeedback, outfitBoardQuery.unique_name, Date.now());
                            }
                        }}
                        disabled={!(outfitFeedback == "good" || outfitFeedback == "bad")}
                    >
                        Submit Board
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={{
                    width: "100%",
                    margin: "-4px"
                }}
            >
                <Grid item xs={4} style={{ height: parseInt(window.innerHeight * 72 / 100), overflow: "auto", backgroundColor: "rgba(200, 200, 200, 0.2)" }}>
                    <ImageGrid images={gridImages}></ImageGrid>
                </Grid>
                <Grid item xs={8}>
                    <Box sx={{ width: "100%", typography: "body1", overflow: "auto", height: parseInt(window.innerHeight * 85 / 100) }}>
                        <TabContext value={selectedCategory}>
                            <TabList
                                value={selectedCategory}
                                onChange={(event, newValue) =>
                                    handleChange(event, newValue, outfitBoardQuery)
                                }
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="fullWidth"
                            >
                                {/* 
                            Create a list of tabs for only categories (tops, bottoms, etc) which have any queries
                            present in the fetched set of queries for the outfit. 
                        */}
                                {CategoriesData.filter(
                                    (category) => outfitBoardQuery.queries[category].length > 0
                                ).map((category, index) => (
                                    <Tab key={index} label={category} value={category} />
                                ))}
                            </TabList>
                            {/* 
                        Shows each of the individual tabs corresponding to a category (tops, bottoms, etc) as the
                        list of searched product images. The view of each category is presented as a
                        TabPanel within the TabContext.  
                    */}
                            <h5>{scored_search_response.retrieval_query ? scored_search_response.retrieval_query.query.broad_category + ": " + scored_search_response.retrieval_query.query.query : ""}</h5>
                            {scored_search_response?.scored_results?.length
                                ? CategoriesData.filter(
                                    (category) => outfitBoardQuery.queries[category].length > 0
                                ).map((category, index) => (
                                    <TabPanel key={index} value={category}>
                                        <ProductImageList
                                            category={category}
                                            results={scored_search_response.scored_results}
                                            retrieval_query={scored_search_response.retrieval_query}
                                            updateSelectedProductForCategory={updateSelectedProductForCategory}
                                            selectedUrl={category in selectedProduct && selectedProduct[category].product ? selectedProduct[category].product.url : 'na'}
                                        ></ProductImageList>
                                    </TabPanel>
                                ))
                                : "No products to show."}
                        </TabContext>
                    </Box>
                </Grid>
            </Grid>


        </div >
    );
}


const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    scored_search_response: state.search.results,
});


const mapDispatchToProps = (dispatch) => ({
    fetchSearchResultsFn: (category, query, color) =>
        dispatch(fetchSearchResults(category, query, color)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectProductsForOutfitBoard);