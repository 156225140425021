import React, { useState, useRef, useEffect } from "react";

import Select, { components } from "react-select";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import Modal from '@mui/material/Modal';
import UploadSection from '../Common/UploadSection';
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from '@mui/material/CircularProgress';
import { Tabs, Tab, Box } from '@mui/material';

function sortFormFields(formFieldsSchema) {
    // Sort the form fields by step number and index
    formFieldsSchema.sort((a, b) => {
        if (a.step !== b.step) {
            return a.step - b.step; // Sort by step number
        } else {
            return a.index - b.index; // Sort by index if step numbers are equal
        }
    });

    // Create a map to group form fields by step number
    const groupedFields = new Map();
    formFieldsSchema.forEach(field => {
        if (!groupedFields.has(field.step)) {
            groupedFields.set(field.step, []);
        }
        groupedFields.get(field.step).push(field);
    });

    // Sort the internal lists by index
    groupedFields.forEach(fields => {
        fields.sort((a, b) => a.index - b.index);
    });

    // Sort the outer list by step number
    const sortedList = Array.from(groupedFields).sort((a, b) => a[0] - b[0]);

    // Return the final result
    return sortedList.map(entry => entry[1]);
}

function SelectRadio({ tagname, value, updateValue, possibleValues, disabled }) {
    return (
        <div style={{ padding: "10px" }}>
            <FormLabel>{tagname}</FormLabel>
            <RadioGroup
                row
                name={tagname}
                value={value}
                onChange={updateValue}
                style={{ marginTop: "10px" }}
            >
                {possibleValues.map((value) => (
                    <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={value}
                        key={value}
                        disabled={disabled}
                    />
                ))}
            </RadioGroup>
        </div>
    );
}

function isString(value) {
    return typeof value === 'string';
}

function isListOfStrings(value) {
    if (!Array.isArray(value)) {
        return false; // Not an array
    }

    return value.every((item) => typeof item === 'string');
}


function getInitValues(isMulti, values) {
    if (!isMulti) {
        return isString(values) ? { value: values, label: values } : values;
    }
    if (isMulti) {
        if (isListOfStrings(values)) {
            return values.map((item) => ({ value: item, label: item }));
        }
        return values;
    }
}

function SelectMultiple({ tagname, values, updateValue, possibleValues, isMulti, disabled }) {
    function onChange(selectedOptions, action) {
        updateValue({ target: { name: tagname, value: selectedOptions } });
    }

    const selectStyles = {
        menu: base => ({
            ...base,
            zIndex: 100,
            backgroundColor: "white",
            color: "black"
        })
    };
    // isString(values) ? { value: values, label: values } : values.
    // The values ideally have the following structure with values and label field. 
    // However when we initialize from an existing structure, we only have a string field.
    // { value: values, label: values }.

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={10} sm={10}>
                <FormLabel>{tagname}</FormLabel>
                <Select
                    styles={selectStyles}
                    isMulti={isMulti}
                    name={tagname}
                    value={getInitValues(isMulti, values)}
                    options={possibleValues}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onChange}
                    closeMenuOnSelect={true}
                    autoBlur={true}
                    isDisabled={disabled}
                />
            </Grid>
            <Grid item xs={2} sm={2}>
            </Grid>
        </Grid>
    );
}

function TextInput({ tagname, value, updateValue, disabled }) {
    return (
        <div >
            <Grid item xs={12} sm={12}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10} sm={10}>
                        <FormLabel>{tagname}</FormLabel>
                        <TextField
                            name={tagname}
                            label={tagname}
                            style={{ width: "100%", marginTop: "20px" }}
                            placeholder={tagname}
                            variant="outlined"
                            value={value}
                            onChange={updateValue}
                            disabled={disabled}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2}>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}


const style = {
    marginLeft: '2%',
    marginTop: '2%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    width: '95%',
    height: '95%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
};

// width: '80vw',
// height: '80vh',
// maxWidth: '100%', // Set maxWidth to take the maxWidth of its parent
// display: 'flex',
// justifyContent: 'center',
// alignItems: 'center',
// zIndex: 100



function ImageUploader({
    values,
    tagname,
    addRepeatedFieldValue,
    handleRepeatedFieldDelete,
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    disabled
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [local_uploaded_images, setLocalUploadedImages] = useState([]);

    useEffect(() => {
        uploadBase64ImagesFn(tagname, local_uploaded_images);
    }, [local_uploaded_images]);

    useEffect(() => {
        if (uploaded_images.tag == tagname && uploaded_images.urls.length > 0) {
            var image_urls = [];
            for (var index = 0; index < uploaded_images.urls.length; index++) {
                image_urls.push(uploaded_images.urls[index].url);
            }
            addRepeatedFieldValue(tagname, image_urls)();
            clearUploadedImagesFn();
        }
    }, [uploaded_images]);

    return (
        <div>
            <FormLabel>{tagname}</FormLabel>
            {is_image_uploading ? (
                <CircularProgress />
            ) : (
                <Button
                    variant="contained"
                    onClick={() => {
                        if (disabled) {
                            ;
                        }
                        else {
                            setModalOpen(true);
                        }
                    }}
                    style={{
                        color: "white",
                        backgroundColor: "rgba(100, 100, 100, 0.9)",
                        marginLeft: "20px"
                    }}
                    disabled={disabled}
                >
                    Upload Photo
                </Button>
            )}
            <Modal open={modalOpen} onClose={() => { setModalOpen(false) }}>
                <Box sx={style}>
                    <UploadSection
                        closeModalFn={() => { setModalOpen(false) }}
                        addUploadedFiles={(images_list) => { setLocalUploadedImages(images_list); }}
                    />
                </Box>
            </Modal>
            <div className="flex  justify-center mb-8">
                <div className="inline-grid grid-cols-3 gap-4 mt-4  max-w-3xl p-4">
                    {values && values.map((item, index) => (
                        <div key={index} className="w-full relative object-cover">
                            <CancelIcon
                                className="text-red-600 text-lg absolute font-bold right-0 cursor-pointer"
                                onClick={() => {
                                    handleRepeatedFieldDelete(tagname, index)();
                                }}
                            />
                            <img
                                src={item}
                                className="h-32 w-32 object-cover rounded-xl"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function AddMultipleStrings({ values, tagname, addRepeatedFieldValue, handleRepeatedFieldDelete, disabled }) {
    const [recentString, setRecentString] = useState('');
    function updateValue(event) {
        setRecentString(event.target.value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (recentString != '') {
                addRepeatedFieldValue(tagname, recentString)();
                setRecentString('');
                event.target.blur();
            }
        }
    };


    return (
        <div>
            <Grid item xs={12} sm={12}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10} sm={10}>
                        <FormLabel>{tagname}</FormLabel>
                        <TextField
                            name={tagname}
                            label={tagname}
                            style={{ width: "100%", }}
                            placeholder={tagname}
                            variant="outlined"
                            value={recentString}
                            onChange={updateValue}
                            disabled={disabled}
                            onKeyPress={handleKeyPress}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} style={{ justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (recentString != '') {
                                    addRepeatedFieldValue(tagname, recentString)();
                                    setRecentString('');
                                }
                            }}
                            style={{
                                color: "white",
                                backgroundColor: "rgba(100, 100, 100, 0.9)",
                                marginTop: "25px"
                            }}
                            startIcon={<PublishIcon />}
                            disabled={disabled}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {
                values && values.map((value, index) => (
                    <Grid item xs={12} sm={12} key={tagname + "_" + index}>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sm={10}>
                                <p>
                                    {value}
                                </p>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <DeleteIcon
                                    style={{ fill: "red" }}
                                    fontSize="large"
                                    disabled={disabled}
                                    onClick={() => {
                                        if (disabled) {
                                            ;
                                        }
                                        else {
                                            handleRepeatedFieldDelete(tagname, index)();
                                        }
                                    }}>
                                </DeleteIcon>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            }
        </div >
    );

}

export function GetDefaultValuesFromSchema(formFieldsSchema) {
    var default_value = {};
    for (var index = 0; index < formFieldsSchema.length; index++) {
        if (formFieldsSchema[index].type == "INPUT_STRING") {
            default_value[formFieldsSchema[index].key] = '';
        }
        else if (formFieldsSchema[index].type == "BOOLEAN") {
            default_value[formFieldsSchema[index].key] = formFieldsSchema[index].meta.false_text;
        }
        else if (formFieldsSchema[index].type == "MULTICLASS") {
            default_value[formFieldsSchema[index].key] = [];
        }
        else if (formFieldsSchema[index].type == "MULTILABEL") {
            default_value[formFieldsSchema[index].key] = [];
        }
        else if (formFieldsSchema[index].type == "MULTISTRING") {
            default_value[formFieldsSchema[index].key] = [];
        }
        else if (formFieldsSchema[index].type == "IMAGE_UPLOADER") {
            default_value[formFieldsSchema[index].key] = [];
        }
        else {
            throw "Error";
        }
    }
    return default_value;
}

export function GetSchemaFormRatingsFromValues(formFieldsSchema, values) {
    var response = {};
    for (var index = 0; index < formFieldsSchema.length; index++) {
        if (formFieldsSchema[index].type == "INPUT_STRING") {
            response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key];
        }
        else if (formFieldsSchema[index].type == "BOOLEAN") {
            response[formFieldsSchema[index].key] = false;
            if (values[formFieldsSchema[index].key] == formFieldsSchema[index].meta.true_text) {
                response[formFieldsSchema[index].key] = true;
            }
        }
        else if (formFieldsSchema[index].type == "MULTICLASS") {
            if (isString(values[formFieldsSchema[index].key])) {
                response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key];
            }
            else {
                if (values[formFieldsSchema[index].key].value == undefined) {
                    response[formFieldsSchema[index].key] = '';
                }
                else {
                    response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key].value;
                }
            }
        }
        else if (formFieldsSchema[index].type == "MULTILABEL") {
            if (isListOfStrings(values[formFieldsSchema[index].key])) {
                response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key];
            }
            else {
                response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key].map((x) => { return x.value });
            }
        }
        else if (formFieldsSchema[index].type == "MULTISTRING") {
            response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key];
        }
        else if (formFieldsSchema[index].type == "IMAGE_UPLOADER") {
            response[formFieldsSchema[index].key] = values[formFieldsSchema[index].key];
        }
        else {
            throw "Error";
        }
    }
    return response;
}

function SchematicFormSingleStep({
    formFieldsSchema,
    values,
    handleChange,
    addRepeatedFieldValue,
    handleRepeatedFieldDelete,
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn
}) {
    return (
        <Grid
            container
            spacing={1}
            style={{
                width: "100%",
                marginBottom: "20px",
                overflowY: "scroll",
                height: "100%"
            }}
        >
            {
                formFieldsSchema.map((item, index) => (
                    <Grid item xs={12} key={index}>
                        <React.Fragment key={item.key}>
                            {item.type == "INPUT_STRING" &&
                                <TextInput
                                    tagname={item.key}
                                    value={values[item.key]}
                                    updateValue={handleChange}
                                    disabled={item.disabled ? item.disabled : false}
                                />
                            }
                            {item.type == "BOOLEAN" &&
                                <SelectRadio
                                    tagname={item.key}
                                    value={values[item.key]}
                                    updateValue={handleChange}
                                    possibleValues={[item.meta.true_text, item.meta.false_text]}
                                    disabled={item.disabled ? item.disabled : false}
                                />
                            }
                            {item.type == "MULTICLASS" &&
                                < SelectMultiple
                                    tagname={item.key}
                                    values={values[item.key]}
                                    updateValue={handleChange}
                                    possibleValues={item.meta.values.map((x) => { return { value: x, label: x } })}
                                    isMulti={false}
                                    disabled={item.disabled ? item.disabled : false}
                                />
                            }
                            {item.type == "MULTILABEL" &&
                                < SelectMultiple
                                    tagname={item.key}
                                    values={values[item.key]}
                                    updateValue={handleChange}
                                    possibleValues={item.meta.values.map((x) => { return { value: x, label: x } })}
                                    isMulti={true}
                                    disabled={item.disabled ? item.disabled : false}
                                />
                            }
                            {item.type == "MULTISTRING" && values[item.key] != undefined &&
                                <AddMultipleStrings
                                    values={values[item.key]}
                                    tagname={item.key}
                                    addRepeatedFieldValue={addRepeatedFieldValue}
                                    handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                                    disabled={item.disabled ? item.disabled : false}
                                />
                            }
                            {item.type == "IMAGE_UPLOADER" && values[item.key] != undefined &&
                                <ImageUploader
                                    values={values[item.key]}
                                    tagname={item.key}
                                    addRepeatedFieldValue={addRepeatedFieldValue}
                                    handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                                    is_image_uploading={is_image_uploading}
                                    uploaded_images={uploaded_images}
                                    uploadBase64ImagesFn={uploadBase64ImagesFn}
                                    clearUploadedImagesFn={clearUploadedImagesFn}
                                    disabled={item.disabled ? item.disabled : false}
                                ></ImageUploader>
                            }
                        </React.Fragment>
                    </Grid>
                ))
            }
        </Grid>
    )
}

const TabPanel = (props) => {
    const { children, value, index, backgroundColor, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
            style={{
                height: '60vh', backgroundColor: backgroundColor
            }}
        >
            {value === index && (
                <Box sx={{ p: 3, height: '100%' }}>
                    <div style={{ height: '100%' }}>{children}</div>
                </Box>
            )}
        </div>
    );
};

function MultiStepSchematicForm({
    formFieldsSchema,
    values,
    handleChange,
    addRepeatedFieldValue,
    handleRepeatedFieldDelete,
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    onSubmit,
    stepToLabel = {}
}) {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleStepChange = (event, newStep) => {
        setActiveStep(newStep);
    };
    const sortedFormFields = sortFormFields(formFieldsSchema);
    return (
        <Box sx={{ width: '100%' }} textAlign="left">
            <Tabs value={activeStep} onChange={handleStepChange} indicatorColor="primary">
                {sortedFormFields.map((stepFields, index) => (
                    <Tab key={index} label={stepFields[0].step in stepToLabel ? stepToLabel[stepFields[0].step] : "Step - " + String(stepFields[0].step)} />
                ))}
                <Tab key={sortedFormFields.length} label={"Finalize"} />
            </Tabs>
            {sortedFormFields.map((stepFields, index) => (
                <TabPanel key={index} value={activeStep} index={index} backgroundColor={"rgba(225, 225, 225, 0.9)"}>
                    <SchematicFormSingleStep
                        formFieldsSchema={stepFields}
                        values={values}
                        handleChange={handleChange}
                        addRepeatedFieldValue={addRepeatedFieldValue}
                        handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                        is_image_uploading={is_image_uploading}
                        uploaded_images={uploaded_images}
                        uploadBase64ImagesFn={uploadBase64ImagesFn}
                        clearUploadedImagesFn={clearUploadedImagesFn}>
                    </ SchematicFormSingleStep>
                </TabPanel>
            ))}
            <TabPanel key={sortedFormFields.length} value={activeStep} index={sortedFormFields.length} backgroundColor={"rgba(225, 225, 225, 0.9)"}>
                <Grid
                    container
                    spacing={1}
                    style={{
                        width: "100%",
                        marginBottom: "20px",
                        height: "100%"
                    }}
                >
                    <Grid style={{ height: '90%', width: "100%", p: 1, overflowY: "scroll", }}>
                        <pre>{JSON.stringify(values, null, 2)}</pre>
                    </Grid>
                    <Grid style={{ height: '10%', width: "100%", textAlign: "center", justifyContent: "center", p: 1 }}>
                        <Button
                            variant="contained"
                            onClick={() => { onSubmit(GetSchemaFormRatingsFromValues(formFieldsSchema, values)); }}
                            sx={{
                                backgroundColor: '#000000', // Specify your custom color
                                width: '200px', // Specify the desired width
                                height: '50px', // Specify the desired height
                                color: '#ffffff', // Specify the text color
                                '&:hover': {
                                    backgroundColor: '#333333', // Specify the hover color
                                },
                            }}
                        >
                            Verify and Submit
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
        </Box>
    );
}

export default function SchematicForm({
    formFieldsSchema,
    values,
    handleChange,
    addRepeatedFieldValue,
    handleRepeatedFieldDelete,
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    onSubmit,
    stepToLabel = {}
}) {
    const sortedFormFields = sortFormFields(formFieldsSchema);
    return (
        <>
            {
                sortedFormFields.length > 1 ?
                    <MultiStepSchematicForm
                        formFieldsSchema={formFieldsSchema}
                        values={values}
                        handleChange={handleChange}
                        addRepeatedFieldValue={addRepeatedFieldValue}
                        handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                        is_image_uploading={is_image_uploading}
                        uploaded_images={uploaded_images}
                        uploadBase64ImagesFn={uploadBase64ImagesFn}
                        clearUploadedImagesFn={clearUploadedImagesFn}
                        onSubmit={onSubmit}
                        stepToLabel={stepToLabel}
                    /> :
                    <Grid
                        container
                        spacing={1}
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Grid style={{ height: '90%', width: "90%", p: 1, overflowY: "scroll", marginLeft: "20px" }}>
                            <SchematicFormSingleStep
                                formFieldsSchema={formFieldsSchema}
                                values={values}
                                handleChange={handleChange}
                                addRepeatedFieldValue={addRepeatedFieldValue}
                                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                                is_image_uploading={is_image_uploading}
                                uploaded_images={uploaded_images}
                                uploadBase64ImagesFn={uploadBase64ImagesFn}
                                clearUploadedImagesFn={clearUploadedImagesFn}>
                            </ SchematicFormSingleStep>
                        </Grid>
                        <Grid style={{ height: '10%', width: "100%", textAlign: "center", justifyContent: "center", p: 1 }}>
                            <Button
                                variant="contained"
                                onClick={() => { onSubmit(GetSchemaFormRatingsFromValues(formFieldsSchema, values)); }}
                                sx={{
                                    backgroundColor: '#000000', // Specify your custom color
                                    width: '200px', // Specify the desired width
                                    height: '50px', // Specify the desired height
                                    color: '#ffffff', // Specify the text color
                                    '&:hover': {
                                        backgroundColor: '#333333', // Specify the hover color
                                    },
                                }}
                            >
                                Verify and Submit
                            </Button>
                        </Grid>
                    </Grid>
            }
        </>
    );
}
