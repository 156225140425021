import React, { useState } from 'react';
import { Button, Grid, FormControl, InputLabel, MenuItem, Select, TextField, Modal } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";

const InstagramInfo = ({ social_metadata, submitSocialInfo }) => {
    const [formValues, setFormValues] = useState(social_metadata ? social_metadata : {
        postTitle: '',
        instagramUrl: '',
        postingDate: null,
        timeOfDay: '',
    });
    const [openModal, setOpenModal] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        submitSocialInfo(formValues);
        // Handle form submission logic here
        setOpenModal(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const buttonStyle = {
        marginRight: '8px',
        backgroundColor: '#FFC3B7', // Pastel red color
        color: 'white',
        padding: '8px 16px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
        transition: 'background-color 0.3s',
    };

    return (
        <div>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <Button style={buttonStyle} onClick={() => { setOpenModal(true); }}>
                        Update Instagram Info
                    </Button>
                </Grid>
                <Grid item>
                    {formValues.instagramUrl && <Button style={buttonStyle} onClick={() => window.open(formValues.instagramUrl, '_blank')}>
                        Open instagram
                    </Button>
                    }
                </Grid>
            </Grid>
            <Modal open={openModal} onClose={handleCloseModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <IconButton sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }} onClick={handleCloseModal}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="instagramUrl"
                                label="Instagram URL"
                                variant="outlined"
                                fullWidth
                                value={formValues.instagramUrl}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="postTitle"
                                label="Post Title"
                                variant="outlined"
                                fullWidth
                                value={formValues.postTitle}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    name="postingDate"
                                    label="Posting Date"
                                    inputVariant="outlined"
                                    format='DD/MM/YYYY'
                                    value={dayjs(formValues.postingDate, 'DD/MM/YYYY')}
                                    onChange={(date) => {
                                        handleChange({ target: { name: 'postingDate', value: dayjs(date).format('DD/MM/YYYY') } })
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth required>
                                <InputLabel>Time of Day</InputLabel>
                                <Select
                                    name="timeOfDay"
                                    value={formValues.timeOfDay}
                                    onChange={handleChange}
                                    label="Time of Day"
                                >
                                    <MenuItem value="morning">Morning</MenuItem>
                                    <MenuItem value="evening">Evening</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div >
    );
};

export default InstagramInfo;
