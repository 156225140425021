import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ColorPicker from "../Common/ColorPicker";
import Modal from "../Common/Modal";
import { fetchSearchResults, setSelectedUrl } from "../../store/searchSlice";
import ProductCard from "./ProductCard";

function PickColorForBroadCategoryFromImage({ pickedColor, setPickedColor, image_url }) {
  const pickColorModalRef = useRef();
  return (
    <>
      <button
        onClick={() => pickColorModalRef.current.open()}
        className="font-semibold text-xl p-2 rounded-full text-blue-500 shadow-lg"
      >
        Pick Color
      </button>
      {pickedColor && (
        <div className="flex space-x-2 justify-center items-center text-center">
          <div
            className="h-8 w-8 rounded-lg"
            style={{
              backgroundColor: pickedColor,
            }}
          ></div>
          <div className="text-lg font-semibold">{pickedColor}</div>
        </div>
      )}
      <Modal ref={pickColorModalRef}>
        <ColorPicker image_url={image_url} pickColorModalRef={pickColorModalRef} setPickedColor={setPickedColor} />
      </Modal>
    </>
  );
}

function DisplaySelectedSearchResult({ selectedSearchResult }) {
  return (
    <>
      {selectedSearchResult.image ? (
        <img
          src={selectedSearchResult.image}
          className="w-[50px] h-[50px] object-contain"
          alt=""
        />
      ) : (
        <img
          src="https://reactjs.org/logo-og.png"
          className="w-[50px] h-[50px] object-contain"
          alt=""
        />
      )}</>
  )
}

function DisplaySelectedSearchResultWithEditableUrlField({ selectedSearchUrl, setSelectedSearchUrl }) {
  const handleChange = (e) => {
    setSelectedSearchUrl({ url: e.target.value, image: "" });
  };

  return (
    <div className="flex space-x-2 items-center">
      <DisplaySelectedSearchResult selectedSearchResult={selectedSearchUrl} />
      <input
        type="text"
        className="border-[1px] border-gray-100 p-3 rounded-lg outline-none shadow-lg "
        placeholder="Product URL"
        value={selectedSearchUrl.url}
        onChange={handleChange}
      />
    </div>
  );
}

function SearchQueryBox({ searchQuery, setSearchQuery, handleSearch }) {
  return (
    <div className="flex space-x-4 justify-center items-center">
      <input
        type="text"
        className="max-w-lg px-5 py-2 rounded-full border-gray-400 border-2 hover:shadow-lg focus-within:shadow-lg outline-none"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <button
        onClick={handleSearch}
        className="px-3 py-2 rounded-full bg-gray-300"
      >
        Search
      </button>
    </div>
  )
}

function SearchResultsBox({ search_results, setSelectedUrl }) {
  const [isSelected, setIsSelected] = useState(new Array(search_results.length).fill(0));

  function updateSelectedValueAtIndex(value, index) {
    if (value) {
      var local_selected = new Array(search_results.length).fill(0);
      local_selected[index] = 1;
      setSelectedUrl({
        url: search_results[index].features.product.url,
        image: search_results[index].features.product.media.base_image !== "" ? search_results[index].features.product.media.base_image : search_results[index].features.product.media.additional_images[0]
      }
      )
      setIsSelected(local_selected);
    }
    else {
      var local_selected = new Array(search_results.length).fill(0);
      setSelectedUrl({
        url: "",
        image: ""
      });
      setIsSelected(local_selected);
    }
  }
  return (
    <div style={{ overflowY: "scroll" }}>
      {search_results?.map((item, index) => (
        <ProductCard
          key={index}
          product_title={item.features.product.name.join().slice(0, 50)}
          product_url={item.features.product.url}
          product_brand={item.features.product.brand}
          product_price={item.features.product.price.discounted_price}
          product_image={item.features.product.media.base_image !== "" ? item.features.product.media.base_image : item.features.product.media.additional_images[0]}
          isSelected={isSelected[index]}
          updateSelectedUrl={(value) => { updateSelectedValueAtIndex(value, index) }}
        >
        </ProductCard>
      ))
      }
    </div>
  )
}

function DisplaySearchResults({ scored_search_response, setSelectedSearchUrl }) {
  return (
    <div
      className=" w-full p-4 space-y-8"
      style={{
        height: (3 / 4) * window.innerHeight,
      }}
    >
      {
        scored_search_response && scored_search_response.scored_results && scored_search_response.scored_results.length > 0 ?
          <>
            <h6>{scored_search_response.retrieval_query.query.query}</h6>
            <SearchResultsBox
              search_results={scored_search_response.scored_results}
              setSelectedUrl={setSelectedSearchUrl}
            ></SearchResultsBox>
          </>

          :
          <></>
      }
      {/* <button
        className={
          !url
            ? "opacity-40 bg-gray-200 font-semibold text-xl shadow-md rounded-full p-2"
            : "text-blue-500 font-semibold text-xl shadow-md rounded-full p-2 hover:opacity-80"
        }
        disabled={!selectedSearchResult}
        onClick={handleDone}
      >
        Done
      </button> */}
    </div >
  );
}

function Search({
  image_url,
  category_to_search,
  fetchSearchResults,
  scored_search_response,
  closeSearchModalFn,
  setSelectedUrlInRedux,
  updateSearchContextInOutfitBoard
}) {
  const [selectedSearchUrl, setSelectedSearchUrl] = useState({ url: '', image: '' });
  const [searchQuery, setSearchQuery] = useState({
    color: '',
    category: category_to_search,
    query: ''
  });

  return (
    <div className="w-full flex justify-center h-full ">
      <div className="space-y-4 justify-center text-center">
        <div className="text-3xl font-bold">Search {category_to_search}</div>
        <div className="space-y-4">
          <div className="space-y-4">
            <PickColorForBroadCategoryFromImage pickedColor={searchQuery.color} setPickedColor={(pickedColor) => {
              let copySearchQuery = { ...searchQuery, color: pickedColor };
              setSearchQuery(copySearchQuery);
            }} image_url={image_url} />
            <DisplaySelectedSearchResultWithEditableUrlField
              selectedSearchUrl={selectedSearchUrl}
              setSelectedSearchUrl={setSelectedSearchUrl}
            >
            </DisplaySelectedSearchResultWithEditableUrlField>
            <SearchQueryBox searchQuery={searchQuery.query} setSearchQuery={(changedQuery) => {
              let copySearchQuery = { ...searchQuery, query: changedQuery };
              setSearchQuery(copySearchQuery);
            }} handleSearch={() => { fetchSearchResults(searchQuery.category, searchQuery.query, searchQuery.color) }}>
            </SearchQueryBox>
            <button
              onClick={() => {
                closeSearchModalFn();
                updateSearchContextInOutfitBoard(
                  searchQuery.category,
                  selectedSearchUrl.url,
                  searchQuery.query,
                  searchQuery.color
                );
                setSelectedUrlInRedux(selectedSearchUrl);
              }}
              className="font-semibold text-xl p-2 rounded-full text-blue-500 shadow-lg"
            >
              Done
            </button>
          </div>
          <DisplaySearchResults
            scored_search_response={scored_search_response}
            setSelectedSearchUrl={setSelectedSearchUrl}
          ></DisplaySearchResults>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  scored_search_response: state.search.results,
});

const mapDispatchToProps = (dispatch) => ({
  // sendInfo: () => dispatch(sendInfo()),
  fetchSearchResults: (category, search_query, color) =>
    dispatch(fetchSearchResults(category, search_query, color)),
  setSelectedUrlInRedux: (selected_url) => dispatch(setSelectedUrl(selected_url))
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
