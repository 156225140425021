import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import UploadSection from "../../components/Common/UploadSection";
import Search from "../../components/OutfitBoards/Search";
import { useNavigate } from "react-router-dom";
import { image_to_recreate } from "../../store/recreateSilce";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useParams } from 'react-router-dom';

import {
  fetch_board_with_id,
  update_board_with_search_product_context,
  initEmptyBoard
} from "../../store/recreateLookSlice";

var defaultOutfitBoardJson = require("./default_recreate.json");

function ImagePickerAndColorPickerModal({ image_to_recreate }) {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div>
      <button
        onClick={() => {
          setModalOpen(true);
        }}
        className="text-blue-500 font-semibold text-xl shadow-md rounded-full p-2 hover:opacity-80"
      >
        {!image_to_recreate ? "Upload Photo" : "Update Photo"}
      </button>
      <Modal open={modalOpen} onClose={() => { setModalOpen(false) }} style={{ width: parseInt(window.innerWidth * 80 / 100) }}>
        <UploadSection closeModalFn={() => { setModalOpen(false) }} />
      </Modal>
      <div className="m-4 flex">
        {image_to_recreate && (
          <img
            src={image_to_recreate?.url}
            alt=""
            className="h-[400px] w-[400px] rounded-xl object-contain shadow-xl hover:opacity-90 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
}

function RecreateLook({
  fetch_board_with_id,
  outfitboard,
  data,
  initEmptyBoard,
  update_board_with_search_product_context
}) {
  const routeParams = useParams();
  const navigate = useNavigate();
  const imageToRecreate = useSelector(image_to_recreate);
  const [clickedCategory, setClickedCategory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (routeParams.board_id) {
      fetch_board_with_id("aesthetic_board_outfits_jan16_1_16_01_2023_sakshi");
    }
    else {
      initEmptyBoard(defaultOutfitBoardJson);
    }
  }, []);

  const handleClick = () => {
    console.log("data send", data);
    navigate("/finalPage");
  };

  return (
    <div className="bg-white overflow-hidden flex justify-center  p-4 w-screen h-screen">
      <div className="flex">
        <ImagePickerAndColorPickerModal image_to_recreate={imageToRecreate} />
        <div>
          {imageToRecreate ? (
            <div className="inline-grid grid-cols-4 gap-4 mt-4 p-4 text-center ">
              {(outfitboard ? Object.keys(outfitboard.products) : [])?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      outfitboard.products[item].length > 0 && outfitboard.products[item][0].url.length > 0
                        ? // selectedCategory?.includes(item)
                        "rounded-full p-1  flex items-center justify-center font-semibold cursor-pointer hover:opacity-80 shadow-xl bg-green-500 text-white"
                        : "rounded-full p-1  flex items-center justify-center font-semibold cursor-pointer hover:opacity-80 shadow-xl"
                    }
                    onClick={() => {
                      setClickedCategory(item);
                      setModalOpen(true);
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          <Modal open={modalOpen} onClose={() => { setModalOpen(false) }} >
            <Search
              image_url={imageToRecreate?.url}
              category_to_search={clickedCategory}
              closeSearchModalFn={() => { setModalOpen(false); }}
              updateSearchContextInOutfitBoard={update_board_with_search_product_context}
            />
          </Modal>
          {imageToRecreate && (
            <div className="w-[400px] flex justify-center mt-4">
              <button
                className="bg-blue-500 px-4 py-2  text-white text-xl rounded-full text-center font-semibold hover:opacity-90"
                onClick={handleClick}
              >
                Done
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  outfitboard: state.recreateLook.outfitboard,
  data: state.recreateLook.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetch_board_with_id: (id) => dispatch(fetch_board_with_id(id)),
  update_board_with_search_product_context: (category, product_url, search_query, color) => dispatch(update_board_with_search_product_context(category, product_url, search_query, color)),
  initEmptyBoard: (outfit_board) => dispatch(initEmptyBoard(outfit_board))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecreateLook);
