import React from "react";
import { Card, Box, Button, CardContent, Typography } from "@mui/material";

function ProductCard(props) {
	const { item, showEditOptions } = props.props;
	return (
		<div>
			<Card
				sx={{ display: "flex" }}
				style={{ width: "400px", marginLeft: "10px" }}
				variant="outlined">
				<img
					src={item.media.representative_image}
					alt="Collection Thumbnail"
					style={{ width: "150px", height: "auto" }}
				/>
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<CardContent sx={{ display: "flex", flexDirection: "column" }}>
						<Typography component="span" variant="h5">
							{item.visible_name}
						</Typography>
						<Typography component="span" variant="h6">
							{item.subtitle}
						</Typography>
					</CardContent>

					{showEditOptions && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginBottom: "10px",
							}}>
							<div style={{ marginLeft: "10px" }}>
								<Button
									variant="outlined"
									onClick={(e) => {
										e.preventDefault();
										console.log("Redirect to", `/collection/edit/${item.id}`)
									}}>
									Edit
								</Button>
							</div>
						</div>
					)}
				</Box>
			</Card>
		</div>
	);
}

export default ProductCard;
