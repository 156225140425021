import React, { useState, useRef, useEffect } from "react";
import update from 'react-addons-update';

function isArray(obj) {
    return !!obj && obj.constructor === Array;
}


export const useCustomForm = ({ initialValues }) => {
    const [values, setValues] = useState(initialValues || {});
    const formRendered = useRef(true);

    useEffect(() => {
        if (!formRendered.current) {
            setValues(initialValues);
        }
        formRendered.current = false;
    }, []);


    const handleChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        if (event.target.type == "checkbox") {
            setValues({ ...values, [name]: event.target.checked });
        }
        else {
            setValues({ ...values, [name]: value });
        }
        try {
            // event persiste does not exist for select multiple.
            event.persist();
        }
        catch (err) {
            ;
        }
    };

    function handleRepeatedFieldChange(index) {
        const func = (event) => {
            setValues(update(values, { [event.target.name]: { [index]: { $set: event.target.value } } }));
        }
        return func;
    };

    function addRepeatedField(field_name) {
        return function (event) {
            setValues(update(values, { [field_name]: { $push: [""] } }));
        };
    };

    function addRepeatedFieldValue(field_name, value) {
        if (isArray(value)) {
            return function (event) {
                setValues(update(values, { [field_name]: { $push: [...value] } }));
            };
        }
        else {
            return function (event) {
                setValues(update(values, { [field_name]: { $push: [value] } }));
            };
        }

    };

    function handleRepeatedFieldDelete(field_name, index) {
        const func = (event) => {
            setValues(update(values, { [field_name]: { $splice: [[[index], 1]] } }));
        }
        return func;
    };



    const init = (updatedValues) => {
        setValues(updatedValues);
    };

    return {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    };
};
