import React, { useState } from "react";
import { connect } from "react-redux";
import SchematicForm from "../../components/SchematicForms";
import { useCustomForm } from '../../components/SchematicForms/CustomForm';
import { GetDefaultValuesFromSchema } from '../../components/SchematicForms';
import { uploadBase64Images, clearUploadedImagesFn } from '../../store/upload';
import { ADDITIONAL_TAGS } from './additional_tags';

const formFieldsSchema = [
    {
        "key": "main_hashtag",
        "type": "MULTICLASS",
        "meta": { "values": ["#lookbook", "#recreate_the_look", "#occasions", "#color_combo", "#ethnic", "#styling_items"] },
        "disabled": false,
        "step": 1,
        "index": 0
    },
    {
        "key": "other_hashtags",
        "type": "MULTILABEL",
        "meta": { "values": ADDITIONAL_TAGS },
        "disabled": false,
        "step": 1,
        "index": 1
    },
    {
        "key": "additional_hashtags",
        "type": "MULTISTRING",
        "meta": {},
        "disabled": false,
        "step": 1,
        "index": 2
    },
    {
        "key": "index",
        "type": "INPUT_STRING",
        "meta": {},
        "step": 1,
        "index": 3
    },
    {
        "key": "context_topic",
        "type": "INPUT_STRING",
        "meta": {},
        "step": 1,
        "index": 4
    },
    {
        "key": "context_image_url",
        "type": "IMAGE_UPLOADER",
        "meta": {},
        "step": 1,
        "index": 5
    },
    {
        "key": "tops",
        "type": "MULTISTRING",
        "meta": {},
        "disabled": false,
        "step": 2,
        "index": 6
    },
    {
        "key": "bottoms",
        "type": "MULTISTRING",
        "meta": {},
        "step": 2,
        "index": 7
    },
    {
        "key": "outerwear",
        "type": "MULTISTRING",
        "meta": {},
        "step": 2,
        "index": 8
    },
    {
        "key": "dresses",
        "type": "MULTISTRING",
        "meta": {},
        "step": 2,
        "index": 9
    },
    {
        "key": "bag",
        "type": "MULTISTRING",
        "meta": {},
        "step": 3,
        "index": 10
    },
    {
        "key": "footwear",
        "type": "MULTISTRING",
        "meta": {},
        "step": 3,
        "index": 11
    },
    {
        "key": "accessories",
        "type": "MULTISTRING",
        "meta": {},
        "step": 3,
        "index": 12
    },
    {
        "key": "canva_link",
        "type": "INPUT_STRING",
        "meta": {},
        "step": 4,
        "index": 13
    },
    {
        "key": "board_image_url",
        "type": "IMAGE_UPLOADER",
        "meta": {},
        "step": 4,
        "index": 14
    }
];

const StepToLabel = {
    1: "Context",
    2: "Main Attire",
    3: "Other Attire",
    4: "Board Details"
}

// const formFieldsSchema = [
//     {
//         "key": "unique_name",
//         "type": "INPUT_STRING",
//         "meta": {},
//         "disabled": true,
//         "step": 1,
//         "index": 1
//     },
//     {
//         "key": "hashtags",
//         "type": "MULTISTRING",
//         "meta": {},
//         "disabled": true,
//         "step": 1,
//         "index": 2
//     },
//     {
//         "key": "context_topic",
//         "type": "INPUT_STRING",
//         "meta": {},
//         "step": 1,
//         "index": 3
//     },
//     {
//         "key": "context_image_url",
//         "type": "IMAGE_UPLOADER",
//         "meta": {},
//         "step": 1,
//         "index": 4
//     }
// ];

// const StepToLabel = {}

export function UploadOutfitBoardPage({
    is_image_uploading,
    uploaded_images,
    uploadBase64ImagesFn,
    clearUploadedImagesFn,
    addOutfitToList,
    init_board = undefined
}) {
    const [initialValues, setInitialValues] = useState(init_board == undefined ? GetDefaultValuesFromSchema(formFieldsSchema) : init_board);

    const {
        values,
        handleChange,
        init,
        handleRepeatedFieldChange,
        addRepeatedField,
        addRepeatedFieldValue,
        handleRepeatedFieldDelete
    } = useCustomForm({
        initialValues
    });

    return (
        <div>
            <SchematicForm
                formFieldsSchema={formFieldsSchema}
                values={values}
                handleChange={handleChange}
                addRepeatedFieldValue={addRepeatedFieldValue}
                handleRepeatedFieldDelete={handleRepeatedFieldDelete}
                is_image_uploading={is_image_uploading}
                uploaded_images={uploaded_images}
                uploadBase64ImagesFn={uploadBase64ImagesFn}
                clearUploadedImagesFn={clearUploadedImagesFn}
                stepToLabel={StepToLabel}
                onSubmit={(formValues) => { addOutfitToList(formValues) }}
            />
        </div>
    );
}


const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    is_image_uploading: state.upload.is_image_uploading,
    uploaded_images: state.upload.uploaded_base64_images
});

const mapDispatchToProps = (dispatch) => ({
    uploadBase64ImagesFn: (tagname, base64_images_mapping) => dispatch(uploadBase64Images(tagname, base64_images_mapping)),
    clearUploadedImagesFn: () => dispatch(clearUploadedImagesFn())
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadOutfitBoardPage);