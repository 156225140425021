import React from 'react';
import { notify } from 'react-notify-toast'
import Notifications from 'react-notify-toast'
import Box from '@mui/material/Box';

const warningColor = {
    background: '#505050',
    text: '#fff'
}

const successColor = {
    background: '#98FB98',
    text: '#000'
}

const errorColor = {
    background: '#F08080',
    text: '#000'
}

export const WarningMessage = function (message) {
    notify.show(message, 'custom', 3000, warningColor);
}

export const SuccessMessage = function (message) {
    notify.show(message, 'custom', 3000, successColor);
}

export const ErrorMessage = function (message) {
    notify.show(message, 'custom', 3000, errorColor);
}

export default function FixedNotification() {
    return (
        <Box
            bgcolor=""
            color="white"
            p={3}
            position="fixed"
            top={60}
            left="48%"
            zIndex="tooltip"
        >
            <Notifications />
        </Box>
    );
}