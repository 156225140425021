import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const intialTime = {
    seconds: 30,
    minutes: 1,
};
const OTPLength = 6;

const OTPPage = ({
    userPhoneNumber,
    userOtp,
    setUserOtp,
    handleSubmit,
    requestOtp,
    setPageState,
}) => {
    const [countDown, setCountDown] = useState(intialTime);
    // The default value is true since we have not yet requested OTP.
    // On first render, we can useEffect, where we request OTP and start the countdown.
    const [hasOtpExpired, setHasOtpExpired] = useState(true);

    const requestOtpAndStartCountDown = () => {
        requestOtp();
        setCountDown(intialTime);
        setHasOtpExpired(false);
    };

    useEffect(() => {
        requestOtpAndStartCountDown();
    }, []);


    useEffect(() => {
        let intervalID = 0;
        if (hasOtpExpired === false) {
            let intervalID = setTimeout(() => {
                if (countDown.seconds > 0) {
                    setCountDown({
                        ...countDown,
                        seconds: countDown.seconds - 1,
                    });
                }
                if (countDown.seconds === 0) {
                    if (countDown.minutes === 0) {
                        clearInterval(intervalID);
                    } else {
                        setCountDown({
                            ...countDown,
                            seconds: 59,
                            minutes: countDown.minutes - 1,
                        });
                    }
                }
            }, 1000);
        }
        return () => {
            clearTimeout(intervalID);
        };
    }, [hasOtpExpired, countDown]);

    useEffect(() => {
        // When the count dow completes, we mark the OTP as expired.
        if (countDown.minutes === 0 && countDown.seconds === 0) {
            setHasOtpExpired(true);
        }
    }, [countDown]);

    const getTimer = () => {
        return (
            "0" +
            countDown.minutes.toString() +
            ":" +
            (countDown.seconds < 10 ? `0${countDown.seconds}` : countDown.seconds)
        );
    };


    return (
        <>
            <Box display="flex" flexDirection="row">
                <Typography
                    style={{
                        marginTop: "0.4em",
                        marginLeft: "0.5em",
                    }}
                >
                    {getTimer()}
                </Typography>
                <Button
                    style={{
                        borderRadius: "2em",
                        textTransform: "none",
                        justifyContent: "flex-end",
                    }}
                    fullWidth
                    onClick={requestOtpAndStartCountDown}
                    disabled={!hasOtpExpired}
                >
                    <Typography> Request new OTP </Typography>
                </Button>
            </Box>
            <OtpInput
                name="otp"
                value={userOtp}
                onChange={(newValue) => { setUserOtp(newValue); }}
                numInputs={OTPLength}
                label="OTP"
                isInputNum={true}
                containerStyle={{ width: "100%" }}
                inputStyle={{
                    width: "100%",
                    height: "3em",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                    fontSize: "1em",
                }}
            />
            <Button
                fullWidth
                variant="contained"
                disabled={!(userOtp.length === OTPLength) || hasOtpExpired}
                style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    borderRadius: "2em",
                }}
                onClick={handleSubmit}
            >
                Submit
            </Button>
            {!hasOtpExpired &&
                <Typography textAlign="right">
                    Sent OTP through SMS to +{userPhoneNumber}
                </Typography>
            }
            <Button
                style={{
                    borderRadius: "2em",
                    textTransform: "none",
                    justifyContent: "flex-start",
                }}
                fullWidth
                onClick={() => {
                    setPageState('home');
                    setUserOtp('');
                }}
            >
                <ArrowBackIcon />
                <Typography>Back</Typography>
            </Button>
        </>
    );
};

export default OTPPage;