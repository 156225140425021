import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

function GetCacheKey(category, query, color) {
  return category + "_" + query + "_" + color;
}

const searchSlice = createSlice({
  name: "search",
  initialState: {
    category: "",
    query: "",
    color: "",
    selected_product_url: { url: '', image: '' },
    results: {},
    search_result_cache: {},
  },
  reducers: {
    getSearchResultsStarted: (state, action) => { },
    getSearchResultsSuccess: (state, action) => {
      state.category = action.payload.data.category;
      state.query = action.payload.data.query;
      state.color = action.payload.data.color;
      state.search_result_cache = { ...action.payload.data.cache_results, [action.payload.data.cache_key]: action.payload.server_response }
      state.results = action.payload.server_response;
    },
    getSearchResultsFailed: (state, action) => { },
    setSelectedProductUrl: (state, action) => {
      state.selected_product_url = action.payload.url_and_image;
    },
    clearSearchCache: (state, action) => {
      state.search_result_cache = {};
      state.category = "";
      state.query = "";
      state.color = "";
      state.results = {};
    },
  },
});

export const {
  getSearchResultsStarted,
  getSearchResultsSuccess,
  getSearchResultsFailed,
  setSelectedProductUrl,
} = searchSlice.actions;

export default searchSlice.reducer;

export const fetchSearchResults =
  (category, search_query, color) => (dispatch, getState) => {
    if (getState().search.query == search_query && getState().search.category == category && getState().search.color == color) {
      return;
    }
    var cache_key = GetCacheKey(category, search_query, color);
    if (cache_key in getState().search.search_result_cache) {
      var cache_results = getState().search.search_result_cache[cache_key];
      return dispatch({
        type: getSearchResultsSuccess.type, payload: {
          data: {
            query: search_query,
            category: category,
            color: color,
            cache_key: cache_key,
            cache_results: {
              ...getState().search.search_result_cache
            }
          },
          server_response: cache_results
        }
      });
    }

    return dispatch(
      apiCallBegan({
        url: "/lookbook/fetch_scored_search_response/",
        method: "post",
        data_to_server: {
          query: search_query,
          category: category,
          color: color,
        },
        data: {
          query: search_query,
          category: category,
          color: color,
          cache_key: cache_key,
          cache_results: {
            ...getState().search.search_result_cache
          }
        },
        onStart: getSearchResultsStarted.type,
        onSuccess: getSearchResultsSuccess.type,
        onFailed: getSearchResultsFailed.type,
      })
    );
  };

export const setSelectedUrl = (selected_url) => (dispatch, getState) => {
  return dispatch({ type: setSelectedProductUrl.type, payload: { url_and_image: selected_url } });
};